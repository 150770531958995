import { Link } from "react-router-dom";
import { PrivateLayout, PublicLayout } from "~/components/layouts";
import { BasicRoute, PrivateRoute } from "~/components/elements";
import { PAGES, MAILTO_ADDRESS } from "~/constants";
import PrivacyPolicyPage from "~/pages/privacy-policy";
import ContactUsPage from "~/pages/contact-us/page/index";
import HomePage from "~/pages/home-page/page/index";
import ContactSubmitted from "~/pages/contact-submitted/index";
import UnsubscribePage from "~/pages/unsubscribe";
import LoginPage from "~/pages/login";
import RegisterPage from "~/pages/register";

export const publicRoutes: BasicRoute[] = [
  {
    path: "/",
    component: PublicLayout,
    exact: false,
    routes: [
      { path: "/", component: HomePage, exact: true },

      {
        path: `${PAGES.POLICY}`,
        exact: true,
        component: PrivacyPolicyPage,
      },
      {
        path: PAGES.CONTACT_US,
        exact: true,
        component: ContactUsPage,
      },
      { path: PAGES.LOGIN, component: LoginPage, exact: true },
      { path: PAGES.REGISTER, component: RegisterPage, exact: true },
      {
        path: `${PAGES.UNSUBSCRIBE}`,
        exact: true,
        component: UnsubscribePage,
      },
      {
        path: PAGES.CONTACT_SUBMITTED,
        exact: true,
        component: ContactSubmitted,
      },
    ],
  },
];

export const privateRoutes: PrivateRoute[] = [
  {
    path: "/app",
    component: PrivateLayout,
    exact: false,
    routes: [],
  },
];
