/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { AppContainer, Col, UiBox, UiHeading, UiLink, UiText, UiTitle } from "~/components/elements";
// eslint-disable-next-line no-restricted-imports
import { BoxProps, HeadingProps, ListItem, OrderedList, TextProps, UnorderedList } from "@chakra-ui/react";
import { useAppSelector, useScrollToTop } from "~/hooks";
import { Link } from "react-router-dom";
import { appConfig } from "~/config";
import { PageLayout } from "~/components/layouts/PageLayout";

function Header(p: HeadingProps) {
  return <UiHeading fontWeight="700px" fontSize="sm" size="sm" lineHeight="24px" fontFamily="SecularOne" {...p} />;
}

function Section(p: BoxProps) {
  return <UiBox mt={8} mb={3} {...p} />;
}

function Content(p: BoxProps) {
  return <UiBox mt={4} mb={3} {...p} />;
}

function Paragraph(p: TextProps) {
  return <UiText size="md" fontFamily="Montserrat" fontSize={{ base: "3xs", lg: "5xs" }} lineHeight="27px" mt={4} mb={3} {...p} />;
}

function TextContent({ children, isBold, textStyle }: { children: string; isBold?: boolean; textStyle?: TextProps }) {
  return (
    <UiText fontFamily="Montserrat" fontSize={{ base: "3xs", lg: "5xs" }} as={isBold ? "b" : undefined} {...textStyle}>
      {children}
    </UiText>
  );
}

function PrivacyPolicyPage() {
  const menuHeight = useAppSelector(state => state.ui.menu.height);

  useScrollToTop();

  return (
    <PageLayout
      metaTagsConfig={{
        title: `Privacy Policy`,
        description: `This Privacy Policy applies to this website and explains how we use a personal information we collect about you when you use our site.`,
        charset: "utf8",
        lang: "en",
        metas: [
          {
            name: "keywords",
            content: "rayyone",
          },
        ],
        links: [{ rel: "canonical", href: window.location.href }],
      }}
    >
      <Col align="center" mb={10} bg="main.background" pt={`${menuHeight - 30}px`} pb={20} color="white">
        <UiHeading lineHeight="60px" color="main.primary" as="h1" size="5xl" mb={4} fontSize="md">
          Privacy policy
        </UiHeading>
        <UiText lineHeight="24px" fontSize="16px" color="#7B7979">
          Last updated December 22, 2022
        </UiText>
      </Col>
      <AppContainer>
        <Section>
          <Header>INTRODUCTION</Header>
          <Content>
            <Paragraph>
              This privacy notice for {appConfig.siteName} ("Company," "we," "us," or "our"), describes how and why we might collect, store, use,
              and/or share ("process") your information when you use our services ( Services"), such as when you:
              <UiBox ml={7} mt={4}>
                <UnorderedList fontSize={{ base: "3xs", lg: "5xs" }}>
                  <ListItem>
                    Visit our website at{" "}
                    <UiLink color="blue" href={appConfig.url}>
                      {appConfig.url}
                    </UiLink>
                    , or any website of ours that links to this privacy notice
                  </ListItem>
                  <ListItem mt={2}>Engage with us in other related ways, including any sales, marketing, or events</ListItem>
                </UnorderedList>
              </UiBox>
            </Paragraph>
            <Paragraph>
              <TextContent isBold>Questions or concerns</TextContent> Reading this privacy notice will help you understand your privacy rights and
              choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or
              concerns, please contact us at hello@rayyone.com.
            </Paragraph>
          </Content>
        </Section>
        <Section>
          <Header>SUMMARY OF KEY POINTS</Header>
          <Content>
            <Paragraph as="b" fontStyle="italic">
              This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the
              link following each key point or by using our table of contents below to find the section you are looking for.
            </Paragraph>
            <Paragraph>
              <TextContent>What personal information do we process?</TextContent> When you visit, use, or navigate our Services, we may process
              personal information depending on how you interact with Rayyone and the Services, the choices you make, and the products and features
              you use.
            </Paragraph>
            <Paragraph>
              <TextContent isBold>Do we process any sensitive personal information?</TextContent> We do not process sensitive personal information.
            </Paragraph>
            <Paragraph>
              <TextContent isBold>Do we receive any information from third parties?</TextContent> We do not receive any information from third
              parties.
            </Paragraph>
            <Paragraph>
              <TextContent>How do we process your information?</TextContent> We process your information to provide, improve, and administer our
              Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for
              other purposes with your consent. We process your information only when we have a valid legal reason to do so.
            </Paragraph>
            <Paragraph>
              <TextContent isBold>In what situations and with which parties do we share personal information?</TextContent> We may share information
              in specific situations and with specific third parties.
            </Paragraph>
            <Paragraph>
              <TextContent isBold>How do we keep your information safe?</TextContent> We have organizational and technical processes and procedures in
              place to protect your personal information. However, no electronic transmission over the internet or information storage technology can
              be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties
              will not be able to defeat our security and improperly collect, access, steal, or modify your information.
            </Paragraph>
            <Paragraph>
              <TextContent isBold>What are your rights?</TextContent> Depending on where you are located geographically, the applicable privacy law
              may mean you have certain rights regarding your personal information.
            </Paragraph>
            <Paragraph>
              <TextContent isBold>How do you exercise your rights?</TextContent> The easiest way to exercise your rights is by filling out our data
              subject request form, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.
            </Paragraph>
          </Content>
        </Section>
        <Section>
          <Header>TABLE OF CONTENTS</Header>
          <Content>
            <UiBox ml={7}>
              <OrderedList fontSize={{ base: "3xs", lg: "5xs" }}>
                <ListItem>WHAT INFORMATION DO WE COLLECT?</ListItem>
                <ListItem>HOW DO WE PROCESS YOUR INFORMATION?</ListItem>
                <ListItem>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</ListItem>
                <ListItem>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</ListItem>
                <ListItem>HOW LONG DO WE KEEP YOUR INFORMATION?</ListItem>
                <ListItem>HOW DO WE KEEP YOUR INFORMATION SAFE?</ListItem>
                <ListItem>CONTROLS FOR DO-NOT-TRACK FEATURES</ListItem>
                <ListItem>DO WE MAKE UPDATES TO THIS NOTICE?</ListItem>
                <ListItem>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</ListItem>
              </OrderedList>
            </UiBox>
          </Content>
        </Section>
        <Section>
          <Header>1. WHAT INFORMATION DO WE COLLECT?</Header>
          <Content>
            <UiTitle fontFamily="SecularOne" fontSize={{ base: "5xs", lg: "sm" }} lineHeight="3sm">
              Personal information you disclose to us
            </UiTitle>
            <Paragraph fontStyle="italic">
              <TextContent isBold>In Short:</TextContent> We collect personal information that you provide to us.
            </Paragraph>
            <Paragraph>
              We collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our
              products and Services, when you participate in activities on the Services, or otherwise when you contact US.
            </Paragraph>
            <Paragraph fontStyle="italic">
              <TextContent isBold>Sensitive Information.</TextContent> We do not process sensitive information.
            </Paragraph>
            <Paragraph>
              All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such
              personal information.
            </Paragraph>
          </Content>
          <Content>
            <UiTitle fontFamily="SecularOne" fontSize={{ base: "5xs", lg: "sm" }} lineHeight="3sm">
              Information automatically collected
            </UiTitle>
            <Paragraph fontStyle="italic">
              <TextContent isBold>In Short:</TextContent> Some information - such as your Internet Protocol (IP) address and/or browser and device
              characteristics -- IS collected automatically when you visit our Services.
            </Paragraph>
            <Paragraph>
              We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your
              specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser
              and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about
              how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and
              operation of our Services, and for our internal analytics and reporting purposes.
            </Paragraph>
            <Paragraph>Like many businesses, we also collect information through cookies and similar technologies.</Paragraph>
            <Paragraph>The information we collect includes:</Paragraph>
            <UiBox ml={7} mt={4}>
              <UnorderedList fontSize={{ base: "3xs", lg: "5xs" }}>
                <ListItem>
                  <TextContent isBold>Log and Usage Data.</TextContent> Log and usage data is service-related, diagnostic, usage, and performance
                  information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how
                  you interact with us, this log data may include your IP address, device information, browser type, and settings and information
                  about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and
                  other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes
                  called "crash dumps"), and hardware settings).
                </ListItem>
                <ListItem mt={2}>
                  <TextContent isBold>Location Data.</TextContent> We collect location data such as information about your device's location, which
                  can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access
                  the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location
                  (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information
                  or by disabling your Location setting on your device. However, if you choose to opt out. you may not be able to use certain aspects
                  of the Services.
                </ListItem>
              </UnorderedList>
            </UiBox>
          </Content>
        </Section>
        <Section>
          <Header>2. HOW DO WE PROCESS YOUR INFORMATION?</Header>
          <Content>
            <Paragraph fontStyle="italic">
              <TextContent isBold>In Short:</TextContent> We process your information to provide, improve, and administer our Services, communicate
              with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your
              consent.
            </Paragraph>
            <Paragraph as="b">
              We process your personal information for a variety of reasons, depending on how you interact with our Services, including:
            </Paragraph>
            <UiBox ml={7} mt={4}>
              <UnorderedList fontSize={{ base: "3xs", lg: "5xs" }}>
                <ListItem>
                  <TextContent isBold>To save or protect an individual's vital interest.</TextContent> We may process your information when necessary
                  to save or protect an individuals vital interest, such as to prevent harm.
                </ListItem>
              </UnorderedList>
            </UiBox>
          </Content>
        </Section>
        <Section>
          <Header>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</Header>
          <Content>
            <Paragraph fontStyle="italic">
              <TextContent isBold>In Short:</TextContent> We may share information in specific situations described in this section and or with the
              following third parties.
            </Paragraph>
            <Paragraph as="b">We may need to share your personal information in the following situations:</Paragraph>
            <UiBox ml={7} mt={4}>
              <UnorderedList fontSize={{ base: "3xs", lg: "5xs" }}>
                <ListItem>
                  <TextContent isBold>Business Transfers.</TextContent> We may share or transfer your information in connection with, or during
                  negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another
                  company.
                </ListItem>
              </UnorderedList>
            </UiBox>
          </Content>
        </Section>
        <Section>
          <Header>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</Header>
          <Content>
            <Paragraph fontStyle="italic">
              <TextContent isBold>In Short:</TextContent> We may use cookies and other tracking technologies to collect and store your information.
            </Paragraph>
            <Paragraph>
              We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information
              about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.
            </Paragraph>
          </Content>
        </Section>
        <Section>
          <Header>5. HOW LONG DO WE KEEP YOUR INFORMATION?</Header>
          <Content>
            <Paragraph fontStyle="italic">
              <TextContent isBold>In Short:</TextContent> We keep your information for as long as necessary to fulfill the purposes outlined in this
              privacy notice unless otherwise required by law.
            </Paragraph>
            <Paragraph>
              We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a
              longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).
            </Paragraph>
            <Paragraph>
              When we have no ongoing legitimate business need to process your personal information. we will either delete or anonymize such
              information, or, if this is not possible (for example. because your personal information has been stored in backup archives), then we
              will securely store your personal information and isolate it from any further processing until deletion is possible.
            </Paragraph>
          </Content>
        </Section>
        <Section>
          <Header>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</Header>
          <Content>
            <Paragraph fontStyle="italic">
              <TextContent isBold>In Short:</TextContent> We aim to protect your personal information through a system of organizational and technical
              security measures.
            </Paragraph>
            <Paragraph>
              We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any
              personal information we process. However. despite our safeguards and efforts to secure your information, no electronic transmission over
              the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers,
              cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or
              modify vour information. Although we will do our best to protect your personal information. transmission of personal information to and
              from our Services is at your own risk. You should only access the Services within a secure environment.
            </Paragraph>
          </Content>
        </Section>
        <Section>
          <Header>7. CONTROLS FOR DO-NOT-TRACK FEATURES</Header>
          <Content>
            <Paragraph>
              Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DN T") feature or setting you can
              activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage
              no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to
              DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for
              online tracking is adopted that we must follow in the future. we will inform you about that practice in a revised version of this
              privacy notice.
            </Paragraph>
          </Content>
        </Section>
        <Section>
          <Header>8. DO WE MAKE UPDATES TO THIS NOTICE?</Header>
          <Content>
            <Paragraph fontStyle="italic">
              <TextContent isBold>In Short:</TextContent> Yes we will update this notice as necessary to stay compliant with relevant laws.
            </Paragraph>
            <Paragraph>
              We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated
              version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by
              prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice
              frequently to be informed of how we are protecting your information.
            </Paragraph>
          </Content>
        </Section>
        <Section>
          <Header>9. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</Header>
          <Content>
            <Paragraph>If you have questions or comments about this notice, you may email us at operations@rayyone.com or by post to.</Paragraph>
            <Paragraph>
              <TextContent>{appConfig.siteName}</TextContent>
              <TextContent>No. 25, 2 Thang 9 street, Hoa Cuong Nam ward, Hai Chau District</TextContent>
              <TextContent>Danang 550000</TextContent>
              <TextContent>Vietnam</TextContent>
            </Paragraph>
          </Content>
        </Section>
      </AppContainer>
    </PageLayout>
  );
}

export default PrivacyPolicyPage;
