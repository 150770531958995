import React, { memo } from "react";
import ReactGA from "react-ga";
import { UiButton, useColorModeValue } from "~/components/elements";

export const SendContactMessage = memo(({ onClickSendContactBtn, loading }: { onClickSendContactBtn: () => void; loading?: boolean }) => {
  const onSubmit = () => {
    ReactGA.event({
      category: "Contact us",
      action: "Click on Send message btn",
      label: "Contact label",
    });
    onClickSendContactBtn();
  };
  const colorText = useColorModeValue("black", "white");

  return (
    <UiButton
      mt="10px"
      py={7}
      onClick={onSubmit}
      borderRadius="20px"
      border=" 2px solid #00A6FF"
      color={colorText}
      bgColor="transparent"
      isLoading={loading}
      fontSize="sm"
      colorScheme="twitter"
    >
      Send Message
    </UiButton>
  );
});
