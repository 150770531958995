import React from "react";
import { PageLayout } from "~/components/layouts/PageLayout";
import Banner from "../components/Banner";

function ContactUsPage() {
  return (
    <PageLayout
      metaTagsConfig={{
        metas: [{ name: "robots", content: "noindex,nofollow" }],
      }}
    >
      <Banner />
    </PageLayout>
  );
}

export default ContactUsPage;
