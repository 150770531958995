import React, { memo, useEffect, useState } from "react";
import { ChakraStylesConfig, CreatableSelect } from "chakra-react-select";
import { Props as SelectProps } from "react-select";
import {
  Box,
  StylesProvider,
  useMultiStyleConfig,
  useTheme,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import isArray from "lodash/isArray";
import uniqBy from "lodash/uniqBy";
import { TagsDropdownOptionProps } from "~/types/general";

declare type Size = "sm" | "md" | "lg";
declare type TagVariant = "subtle" | "solid" | "outline" | undefined;
declare type SelectedOptionStyle = "color" | "check";
type SizeProps = {
  sm: string | number;
  md: string | number;
  lg: string | number;
};

export interface UiCreatableDropdownProps
  extends Omit<SelectProps, "onChange"> {
  forcedValue?: TagsDropdownOptionProps[];
  size?: Size;
  minW?: string;
  includeAll?: boolean;
  colorScheme?: string;
  isInvalid?: boolean;
  tagVariant?: TagVariant;
  hasStickyGroupHeaders?: boolean;
  selectedOptionStyle?: SelectedOptionStyle;
  selectedOptionColor?: string;
  multiple?: boolean;
  shouldResetOnChange?: boolean;
  shouldResetOnSubmit?: boolean;
  shouldResetOnCreate?: boolean;
  onSelect?: (value?: string | number) => any;
  onSubmit?: (value?: any) => any;
  onInputChange?: (value?: string) => void;
  onCreateOption?: (value: string) => void;
  onChange?: (
    value: any,
    action: any,
    prevSelected?: TagsDropdownOptionProps[]
  ) => void;
  customChakraStyles?: ChakraStylesConfig;
}

function Menu({ children, w, innerProps, selectProps: { size } }: any) {
  const menuStyles = useMultiStyleConfig("Menu", {});
  const chakraTheme = useTheme();
  const borderRadii: SizeProps = {
    sm: chakraTheme.radii.sm,
    md: chakraTheme.radii.md,
    lg: chakraTheme.radii.md,
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: "100%",
        my: "8px",
        w,
        zIndex: 1,
        overflow: "auto",
        rounded: borderRadii[size as Size],
      }}
      {...innerProps}
    >
      <StylesProvider value={menuStyles}>{children}</StylesProvider>
    </Box>
  );
}

export const UiCreatableDropdown = memo(
  React.forwardRef(
    (
      {
        multiple = false,
        components = {},
        size = "sm",
        minW,
        onInputChange,
        customChakraStyles,
        onChange,
        onSubmit,
        onCreateOption,
        shouldResetOnChange,
        shouldResetOnSubmit,
        shouldResetOnCreate,
        forcedValue,
        defaultValue,
        options,
        ...rest
      }: UiCreatableDropdownProps,
      ref
    ) => {
      const ddRef = React.useRef(null);

      const [selectedData, setSelectedData] = useState<
        TagsDropdownOptionProps[]
      // @ts-ignore
      >(defaultValue || []);
      const [isMenuOpen, setIsMenuOpen] = useState(false);
      const dispatch = useDispatch();

      useEffect(() => {
        const getUniqueTags = () => {
          const tagsValue = forcedValue?.filter(
            (item: TagsDropdownOptionProps) => item.value
          );
          return uniqBy(
            tagsValue,
            (item: TagsDropdownOptionProps) => item.value
          );
        };
        if (forcedValue !== undefined) {
          setSelectedData(getUniqueTags());
        }
      }, [dispatch, forcedValue]);

      const chakraStyles: ChakraStylesConfig = {
        control: (provided: any, state: any) => ({
          ...provided,
          minWidth: minW || "10em",
          fontWeight: "normal",
          textTransform: "none",
        }),
        menuList: (provided: any, state: any) => ({
          ...provided,
          fontWeight: "normal",
          textTransform: "none",
        }),
      };

      const onChangeLocal = async (value: any, action: any) => {
        const prevSelectedData = [
          ...(isArray(selectedData) ? selectedData : [selectedData]),
        ];
        setSelectedData(value);
        if (onChange) {
          onChange(value, action, prevSelectedData);
          if (shouldResetOnChange) {
            setSelectedData([]);
            if (ddRef && ddRef.current) {
              // @ts-ignore
              ddRef.current?.blur();
            }
          }
        }
      };

      const onCreateOptionLocal = (value: string) => {
        if (onCreateOption) {
          onCreateOption(value);
          if (shouldResetOnCreate) {
            setSelectedData([]);
            if (ddRef && ddRef.current) {
              // @ts-ignore
              ddRef.current?.blur();
            }
          }
        }
      };

      return (
        <CreatableSelect
          ref={ddRef}
          size={size}
          isMulti={multiple}
          value={selectedData}
          options={options}
          // menuIsOpen // Use this one to debug
          components={{
            // eslint-disable-next-line react/no-unstable-nested-components
            Menu: (props: any) => <Menu w="100%" {...props} />,
            ...components,
          }}
          chakraStyles={{ ...chakraStyles, ...customChakraStyles }}
          onChange={onChangeLocal}
          closeMenuOnSelect
          onInputChange={onInputChange}
          onCreateOption={onCreateOptionLocal}
          onMenuOpen={() => setIsMenuOpen(true)}
          onMenuClose={() => setIsMenuOpen(false)}
          onKeyDown={(e: any) => {
            if (e.key === "Enter" && !isMenuOpen) {
              if (onSubmit) {
                onSubmit(selectedData);
                if (shouldResetOnSubmit) {
                  setSelectedData([]);
                  if (ddRef && ddRef.current) {
                    // @ts-ignore
                    ddRef.current?.blur();
                  }
                }
              }
            }
          }}
          {...rest}
        />
      );
    }
  )
);
