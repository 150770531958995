import { useColorModeValue, useMediaQuery } from "@chakra-ui/react";
import React, { memo, useEffect, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { scroller } from "react-scroll";
import { MAILTO_ADDRESS, MAILTO_HELLO_ADDRESS, QUERY_MOBILE, SECTION_IDS } from "~/constants";
import { useAppDispatch } from "~/hooks";
import { setMenuHeight } from "~/redux/ui/slice";
import { sharedStyle } from "~/theme/shared-style";
import { logo } from "~/assets/img";
import { appConfig } from "~/config";
import {
  AppContainer,
  Col,
  Row,
  UiBox,
  UiButton,
  UiDivider,
  UiGrid,
  UiGridItem,
  UiIcon,
  UiImage,
  UiLink,
  UiMenu,
  UiMenuButton,
  UiMenuItem,
  UiMenuList,
  UiPrimaryButton,
  UiText,
} from "~/components/elements/index";
import ReactGA from "react-ga4";
import { FiChevronDown } from "react-icons/fi";

interface Props {}

export const MainNavigation = memo((props: Props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const ref = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const [isAtTop, setIsAtTop] = React.useState(true);
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {
    ssr: false,
  });
  const colorText = useColorModeValue("main.primary", "secondary.400");

  const handleClickButton = (text: string) => {
    if (location.pathname !== "/") {
      history.push("/");
    }

    setTimeout(() => {
      scroller.scrollTo(text, {
        smooth: true,
        duration: 1000,
      });
    }, 100);
  };

  const handleContactUs = () => {
    ReactGA.event({
      category: "Contact Us",
      action: "Click on Contact us on navigation bar",
      label: "Contact us",
    });
    window.location.href = MAILTO_HELLO_ADDRESS;
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const renderNavbarDesktop = () => (
    <UiGridItem colSpan={{ base: 10, md: 10, lg: 8 }}>
      <Row alignItems="center" justifyContent="flex-end">
        <Row mr={7} fontWeight="bold" gap={10} px={5} alignItems="center" color="black" fontSize="20px">
          <UiLink fontSize="4xs">Pricing</UiLink>
          <UiLink fontSize="4xs">About us</UiLink>
        </Row>

        <Row gap={5}>
          <UiPrimaryButton
            onClick={handleContactUs}
            px={4}
            py={7}
            borderRadius={8}
            border="solid"
            borderColor="#D9D9D9"
            _hover={{ bg: undefined }}
            fontSize="4xs"
            bg="white"
            color="black"
            colorScheme="twitter"
          >
            Request a demo
          </UiPrimaryButton>
          <UiPrimaryButton
            onClick={() => handleClickButton(SECTION_IDS.JOIN_BETA)}
            p={7}
            borderRadius={8}
            _hover={{ bg: undefined }}
            fontSize="4xs"
            bg="black"
            color="white"
            colorScheme="twitter"
          >
            Join Beta
          </UiPrimaryButton>
        </Row>
      </Row>
    </UiGridItem>
  );

  const renderNavbarMobile = () => (
    <Row alignItems="center" justifyContent="flex-end">
      <Row fontWeight="400" gap={5} px={5} alignItems="flex-end" color="secondary.text" fontFamily="SecularOne">
        <UiPrimaryButton
          onClick={handleContactUs}
          px={4}
          py={5}
          fontSize="3xs"
          borderRadius={8}
          border="solid"
          borderColor="#D9D9D9"
          _hover={{ bg: undefined }}
          bg="white"
          color="black"
        >
          Request a demo
        </UiPrimaryButton>
        <UiPrimaryButton
          onClick={() => handleClickButton(SECTION_IDS.JOIN_BETA)}
          p={5}
          borderRadius={8}
          fontSize="3xs"
          _hover={{ bg: undefined }}
          bg="black"
          color="white"
        >
          Join Beta
        </UiPrimaryButton>
      </Row>
    </Row>
  );

  useEffect(() => {
    dispatch(setMenuHeight({ height: ref?.current?.clientHeight! }));
    window.onscroll = () => {
      setIsAtTop(window.scrollY < 10);
    };
  }, [dispatch]);

  return (
    <UiBox
      ref={ref}
      pos="sticky"
      right={0}
      left={0}
      height={{ base: "110px", lg: "80px" }}
      top={-1}
      bg="white"
      zIndex={1001}
      sx={{ transition: "all .3s ease-in" }}
      borderBottom={!isAtTop ? "2px" : "0"}
      borderStyle="solid"
      borderColor="#f1eee4"
      bgColor={!isAtTop ? "white" : "main.background"}
    >
      <AppContainer as={Col} justifyContent="space-between" h="100%" maxW={sharedStyle.menuMaxW}>
        <UiGrid templateColumns="repeat(10, 1fr)" py={5} gap={{ md: 4, lg: 1 }} alignItems="center">
          <UiGridItem colSpan={{ base: 10, md: 5, lg: 2 }}>
            <Row align={{ base: "start", lg: "center" }}>
              <Col>
                <Row>
                  <UiLink _hover={{ textDecoration: "none" }} as={Link} to="/" ml={4}>
                    <UiText
                      _hover={{ cursor: "pointer" }}
                      fontWeight="bold"
                      color="black"
                      fontSize={{ base: "3sm", lg: "sm" }}
                      w="120%"
                      lineHeight="2lg"
                    >
                      {appConfig.siteName}
                    </UiText>
                  </UiLink>
                </Row>
                {!isDesktop && renderNavbarMobile()}
              </Col>
            </Row>
          </UiGridItem>
          {isDesktop && renderNavbarDesktop()}
        </UiGrid>
      </AppContainer>
    </UiBox>
  );
});
