import {
  AnyAction,
  AsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  DEFAULT_REQUEST_STATE,
  RejectErrorValue,
  RequestState,
} from "redux-thunk-kit";

interface PendingReducer {
  meta?: { arg: { page?: number; [key: string]: any } };
  type: string;
}

interface RejectedReducer {
  payload?: RejectErrorValue;
  type: string;
}

type GenericAsyncThunk = AsyncThunk<any, any, any>;
type PendingAction = ReturnType<GenericAsyncThunk["pending"]>;
type FulfilledAction = ReturnType<GenericAsyncThunk["fulfilled"]>;
type RejectedAction = ReturnType<GenericAsyncThunk["rejected"]>;

interface InitialState {
  messages: {
    error?: string | string[];
    success?: string;
  };
  menu: {
    height: number;
    isShowMobileMenu?: boolean;
  };
  boolBag: {
    isInitAppDone: boolean;
  };
  imageViewModal: {
    isShowing: boolean;
    url: string | undefined;
  };

  [actionTypePrefix: string]: RequestState | any;
}

const initialState: InitialState = {
  messages: {
    error: undefined,
    success: undefined,
  },
  menu: {
    height: 0,
    isShowMobileMenu: false,
  },
  boolBag: {
    isInitAppDone: false,
  },
  imageViewModal: { isShowing: false, url: "" },
};

function isPendingAction(action: AnyAction): action is PendingAction {
  return action.type.endsWith("/pending");
}

function isFulfilledAction(action: AnyAction): action is FulfilledAction {
  return action.type.endsWith("/fulfilled");
}

function isRejectedAction(action: AnyAction): action is RejectedAction {
  return action.type.endsWith("/rejected");
}

// Slice
const ui = createSlice({
  name: "uis",
  initialState,
  reducers: {
    notify(
      state,
      {
        payload,
      }: PayloadAction<{ type?: "error" | "success"; message: string }>
    ) {
      const { type = "success", message } = payload;
      state.messages[type] = message;
    },
    setMenuHeight(state, { payload }: PayloadAction<{ height: number }>) {
      const { height } = payload;
      state.menu.height = height;
    },
    showMobileMenu(state, { payload }: PayloadAction<{ value: boolean }>) {
      const { value } = payload;
      state.menu.isShowMobileMenu = value;
    },
    setShowImageViewModal(
      state,
      { payload }: PayloadAction<{ isShowing: boolean; url: string }>
    ) {
      state.imageViewModal = payload;
    },
    setUiBoolBag(
      state,
      {
        payload,
      }: PayloadAction<{ key: keyof InitialState["boolBag"]; value: boolean }>
    ) {
      const { key, value } = payload;
      state.boolBag[key] = value;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isPendingAction,
      (state, { meta, type }: PendingReducer) => {
        const typePrefix = type.replace("/pending", "");
        state[typePrefix] = state[typePrefix] || DEFAULT_REQUEST_STATE;
        state[typePrefix] = {
          ...state[typePrefix],
          loading: true,
          error: null,
          firstPage: (meta?.arg?.page || 0) <= 1,
        };
        state.messages.error = undefined;
      }
    );
    builder.addMatcher(isFulfilledAction, (state, { type }) => {
      const typePrefix = type.replace("/fulfilled", "");
      state[typePrefix] = state[typePrefix] || DEFAULT_REQUEST_STATE;
      state[typePrefix] = {
        ...state[typePrefix],
        loading: false,
        error: null,
      };
      state.messages.error = undefined;
    });
    builder.addMatcher(
      isRejectedAction,
      // @ts-ignore
      (state, { payload, type }: RejectedReducer) => {
        const typePrefix = type.replace("/rejected", "");
        state[typePrefix] = state[typePrefix] || DEFAULT_REQUEST_STATE;
        state[typePrefix] = {
          ...state[typePrefix],
          loading: false,
          error: payload?.errMsg,
          errCode: payload?.errCode,
          contexts: payload?.contexts,
        };

        let errorMessage: string | string[] = payload?.errMsg || "";
        if (payload?.messageBag) {
          errorMessage = Object.values(payload?.messageBag || {});
        }
        state.messages.error = errorMessage;
      }
    );
  },
});

export const {
  notify,
  setMenuHeight,
  setShowImageViewModal,
  setUiBoolBag,
  showMobileMenu,
} = ui.actions;

export default ui.reducer;
