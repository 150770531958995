import React, { memo } from "react";
import forEach from "lodash/forEach";
import isArray from "lodash/isArray";
import isString from "lodash/isString";
import { ValidationErrors } from "~/types/general";
import { IconType } from "react-icons";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertProps,
  AlertStatus,
  AlertTitle,
  Stack,
  Text,
} from "@chakra-ui/react";

interface Props extends AlertProps {
  body?: string | string[] | ValidationErrors;
  icon?: IconType | React.ReactNode;
  header?: string;
  type?: AlertStatus;
}

export const AppMessage = memo(
  ({ header, body, type = "error", icon }: Props) => {
    if (!body) {
      return null;
    }

    const getHeaderTitle = () => {
      if (header) {
        return header;
      }
      if (type === "error") {
        return "Something went wrong!";
      }
      if (type === "success") {
        return "Success!";
      }
      return null;
    };

    const renderBody = () => {
      if (isString(body)) {
        return <Text>{body}</Text>;
      }

      function renderArrayMessages(contents: string[]) {
        return (
          <Stack spacing={3}>
            {contents.map((i) => (
              <Text key={i}>{i}</Text>
            ))}
          </Stack>
        );
      }

      if (isArray(body)) {
        return renderArrayMessages(body);
      }

      let listMsg: string[] = [];
      forEach(body as ValidationErrors, (itemMessages: string[]) => {
        listMsg = listMsg.concat(itemMessages);
      });
      return renderArrayMessages(listMsg);
    };

    return (
      <Alert
        status={type}
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        minH="150px"
        mb={5}
      >
        <AlertIcon boxSize="10px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          {getHeaderTitle()}
        </AlertTitle>
        <AlertDescription maxWidth="sm">{renderBody()}</AlertDescription>
      </Alert>
    );
  }
);
