import { createSlice } from "@reduxjs/toolkit";
import { BaseReducerState } from "redux-thunk-kit";

interface InitialState extends BaseReducerState {}

const initialState: InitialState = {
  //
};

const apps = createSlice({
  name: "apps",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // builder.addCase(fetchApps.fulfilled, (state, {payload}) => {
    //   //
    // });
  },
});

// export const {} = apps.actions;

export default apps.reducer;
