import { useColorModeValue, useMediaQuery } from "@chakra-ui/react";
import React, { memo, useEffect, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  AppContainer,
  BasicRoute,
  Col,
  Row,
  UiBox,
  UiButton,
  UiGrid,
  UiGridItem,
  UiIcon,
  UiImage,
  UiLink,
  UiPrimaryButton,
  UiText,
} from "~/components/elements";
import { MobileNavigation } from "~/components/layouts/MobileNavigation";
import { MAILTO_ADDRESS, PAGES, QUERY_MOBILE } from "~/constants";
import { setMenuHeight, showMobileMenu } from "~/redux/ui/slice";
import { publicRoutes } from "~/routes";
import { getCurrentUserFromStorage } from "~/services/auth";
import { useAppDispatch } from "~/hooks";
import { appConfig } from "~/config";
import { sharedStyle } from "~/theme/shared-style";
import { scroller } from "react-scroll";
import { logo } from "~/assets/img";

interface Props {}

export const renderMenuItems = (
  item: BasicRoute,
  parentPath = "",
  isMobile = false,
  cb: any = undefined
): any => {
  if (item.routes) {
    if (item.label) {
      // Mean has nested children
      return renderParentMenuItem(item, item.path, isMobile, cb);
    }
    // Mean this is a layout component
    return item.routes.map((route) =>
      renderMenuItems(route, parentPath, isMobile, cb)
    );
  }
  if (item.label) {
    const fullPath =
      parentPath && parentPath !== "/"
        ? `${parentPath}${item.path}`
        : item.path;
    return (
      <SingleMenuItem
        key={fullPath}
        item={item}
        parentPath={parentPath}
        isMobile={isMobile}
        cb={cb}
      />
    );
  }
  return null;
};

const renderParentMenuItem = (
  item: BasicRoute,
  parentPath = "",
  isMobile = false,
  cb: any = undefined
) => (
  <UiBox key={`parent-menu-${item.path}`} _hover={{ cursor: "pointer" }}>
    <Row px={5} alignItems="flex-end">
      <UiLink _hover={{ color: "link.active" }}>{item.label}</UiLink>
    </Row>
  </UiBox>
);

function SingleMenuItem({
  item,
  parentPath = "",
  isMobile = false,
  cb = undefined,
}: {
  item: BasicRoute;
  parentPath: string;
  isMobile: boolean;
  cb: any;
}) {
  const { pathname } = useLocation();

  const fullPath =
    parentPath && parentPath !== "/" ? `${parentPath}${item.path}` : item.path;
  const isActive = pathname.includes(fullPath);

  const history = useHistory();
  const location = useLocation();

  const handleClickJoinBeta = () => {
    if (location.pathname !== "/") {
      history.push("/");
    }

    setTimeout(() => {
      scroller.scrollTo("joinbeta", {
        smooth: true,
        duration: 1000,
      });
    }, 100);
  };

  if (fullPath !== item.path) {
    // Submenu
    return (
      <UiBox
        key={`menu-${fullPath}`}
        fontWeight="bold"
        my={2}
        _hover={{ cursor: "pointer" }}
      >
        <UiLink as={Link} to={fullPath} _hover={{ color: "link.active" }}>
          {item.label}
        </UiLink>
      </UiBox>
    );
  }
  if (isMobile) {
    return (
      <UiBox
        key={`menu-${fullPath}`}
        fontWeight="bold"
        _hover={{ cursor: "pointer" }}
      >
        <Row alignItems="flex-end" my={5}>
          <UiLink
            onClick={() => cb && cb()}
            as={Link}
            to={fullPath}
            fontSize="xl"
            _focus={{ boxShadow: "none" }}
            _hover={{ color: isActive ? undefined : "link.active" }}
            zIndex={2}
          >
            {item.label}
          </UiLink>
        </Row>
      </UiBox>
    );
  }

  if (item.action === "href") {
    return (
      <UiBox
        key={`menu-${fullPath}`}
        fontWeight="bold"
        _hover={{ cursor: "pointer" }}
      >
        <Row mr="2.5em" alignItems="flex-start">
          <UiLink
            fontFamily="SecularOne"
            _focus={{ boxShadow: "none" }}
            href={item.path}
            _hover={{ color: isActive ? undefined : "link.active" }}
            fontSize={{ base: "3xs", lg: "sm" }}
            fontWeight={500}
            zIndex={2}
            color="secondary.text"
          >
            {item.label}
          </UiLink>
        </Row>
      </UiBox>
    );
  }

  return (
    <UiBox
      key={`menu-${fullPath}`}
      fontWeight="bold"
      _hover={{ cursor: "pointer" }}
    >
      <Row
        mr={{ base: "1.5em", lg: "2.5em" }}
        alignItems="flex-start"
        onClick={
          item.label === "Join Beta" ? () => handleClickJoinBeta() : () => {}
        }
      >
        <UiLink
          fontFamily="SecularOne"
          as={Link}
          _focus={{ boxShadow: "none" }}
          to={fullPath}
          _hover={{ color: isActive ? undefined : "link.active" }}
          fontSize={{ base: "3xs", lg: "sm" }}
          fontWeight={500}
          zIndex={2}
          color="secondary.text"
        >
          {item.label}
        </UiLink>
      </Row>
    </UiBox>
  );
}

export const Navigation = memo((props: Props) => {
  const dispatch = useAppDispatch();
  const { user: currentUser } = getCurrentUserFromStorage();
  const history = useHistory();
  const ref = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const [isAtTop, setIsAtTop] = React.useState(true);
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {
    ssr: false,
  });
  const colorText = useColorModeValue("main.primary", "secondary.400");

  const handleClickDownload = () => {
    if (location.pathname !== "/") {
      history.push("/");
    }

    setTimeout(() => {
      scroller.scrollTo("joinnow", {
        smooth: true,
        duration: 1000,
      });
    }, 100);
  };

  useEffect(() => {
    dispatch(setMenuHeight({ height: ref?.current?.clientHeight! }));
    window.onscroll = () => {
      setIsAtTop(window.scrollY < 10);
    };
  }, [dispatch]);

  return currentUser == null ? null : (
    <UiBox
      ref={ref}
      pos="sticky"
      right={0}
      left={0}
      height="84px"
      top={-1}
      bg={!isAtTop ? "white" : "white"}
      zIndex={1001}
      sx={{ transition: "all .3s ease-in" }}
      borderBottom={!isAtTop ? "2px" : "0"}
      borderStyle="solid"
      borderColor="#f1eee4"
    >
      <AppContainer
        as={Col}
        justifyContent="space-between"
        h="100%"
        maxW={sharedStyle.menuMaxW}
      >
        <UiGrid
          templateColumns="repeat(10, 1fr)"
          py={3}
          gap={{ md: 4, lg: 1 }}
          alignItems="center"
        >
          <UiGridItem colSpan={{ base: 10, md: 5, lg: 2 }}>
            <Row align={{ base: "start", lg: "center" }}>
              {/* {!isDesktop && (
                <UiIcon
                  color="main.primary"
                  as={GiHamburgerMenu}
                  boxSize="2.3rem"
                  mr={3}
                  onClick={() => dispatch(showMobileMenu({ value: true }))}
                  _hover={{ cursor: "pointer" }}
                />
              )} */}
              <UiLink as={Link} to="/">
                <UiImage src={logo} width={{ base: "25px", lg: "40px" }} />
              </UiLink>
              <Col>
                <Row>
                  <UiLink
                    _hover={{ textDecoration: "none" }}
                    as={Link}
                    to="/"
                    ml={4}
                  >
                    <UiText
                      _hover={{ cursor: "pointer" }}
                      fontWeight="400"
                      color={colorText}
                      fontSize={{ base: "3sm", lg: "md" }}
                      w="120%"
                      fontFamily="SecularOne"
                      lineHeight="2lg"
                    >
                      {appConfig.siteName}
                    </UiText>
                  </UiLink>
                </Row>

                <Row ml={4}>
                  {!isDesktop && (
                    <Row alignItems="center" justifyContent="flex-end">
                      {publicRoutes.map((item) => renderMenuItems(item))}
                    </Row>
                  )}
                </Row>
              </Col>
            </Row>
          </UiGridItem>
          {/* {!isDesktop && <MobileNavigation />} */}
          {isDesktop && (
            <UiGridItem colSpan={{ base: 10, md: 10, lg: 8 }}>
              <Row alignItems="center" justifyContent="flex-end">
                {publicRoutes.map((item) => renderMenuItems(item))}
                <UiPrimaryButton
                  padding={8}
                  fontSize="sm"
                  bg="main.primary"
                  // border={!isAtTop ? "none" : "1px solid black"}
                  // onClick={() => {
                  //   history.push(PAGES.CONTACT_US);
                  // }}
                  onClick={handleClickDownload}
                >
                  Download
                </UiPrimaryButton>
              </Row>
            </UiGridItem>
          )}
        </UiGrid>
      </AppContainer>
    </UiBox>
  );
});
