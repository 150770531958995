import { appStoreLink, googlePlayLink, MAILTO_HELLO_ADDRESS, PAGES } from "~/constants/app";
import { FaYoutube, FaTwitter, FaLinkedinIn, FaFacebook } from "react-icons/fa";

export const FOOTER_MENU = [
  {
    key: 1,
    title: "Company",
    pages: [
      // { key: 1, title: "About us", url: PAGES.CONTACT_US },
      { key: 2, title: "Partner with us", url: MAILTO_HELLO_ADDRESS },
      { key: 3, title: "Investment", url: MAILTO_HELLO_ADDRESS },
      { key: 4, title: "Careers", url: MAILTO_HELLO_ADDRESS },
    ],
  },
  // {
  //   key: 2,
  //   title: "Product",
  //   pages: [
  //     { key: 1, title: "Features", url: PAGES.CONTACT_US },
  //     { key: 2, title: "Use cases", url: PAGES.CONTACT_US },
  //   ],
  // },
  {
    key: 3,
    title: "Resources",
    pages: [
      { key: 1, title: "Contact support", url: PAGES.CONTACT_US },
      { key: 2, title: "iOS app", url: appStoreLink },
      { key: 3, title: "Android app", url: googlePlayLink },
    ],
  },
];

export const FOOTER_SOCIALS = [
  // {
  //   key: 1,
  //   name: "twitter",
  //   icon: FaTwitter,
  //   url: "",
  // },
  // {
  //   key: 2,
  //   name: "youtube",
  //   icon: FaYoutube,
  //   url: "",
  // },
  {
    key: 3,
    name: "linkedin",
    icon: FaLinkedinIn,
    url: "https://www.linkedin.com/company/rayyone/",
  },
  {
    key: 4,
    name: "facebook",
    icon: FaFacebook,
    url: "https://www.facebook.com/rayyone.vn",
  },
];
