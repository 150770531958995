import React, { useEffect } from "react";
import {
  AppContainer,
  Col,
  Row,
  UiBox,
  UiCenter,
  UiDivider,
  UiHeading,
  UiIcon,
  UiImage,
  UiLink,
  UiListItem,
  UiTab,
  UiTabList,
  UiTabPanel,
  UiTabPanels,
  UiTabs,
  UiText,
  UiUnorderedList,
  useMediaQuery,
} from "~/components/elements";
import { QUERY_MOBILE, SECTION_IDS, appStoreLink, googlePlayLink } from "~/constants";
import { TABS, WORKS } from "~/constants/overviewTabs";
import { sharedStyle } from "~/theme/shared-style";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { MotionSlideFade } from "~/components/shared/MotionSlideFade";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { appStore, googlePlay, imagePlaceholder } from "~/assets/img";
import { FaCircle } from "react-icons/fa";
import { DotProps } from "react-multi-carousel/lib/types";

const CustomDot = ({ active, onClick }: DotProps) => (
  <UiBox onClick={onClick}>
    <UiIcon as={FaCircle} boxSize={6} color={active ? "main.primary" : "main.border"} />
  </UiBox>
);

const OverviewTabs = () => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {
    ssr: false,
  });

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  useEffect(() => {
    WORKS.forEach(item => {
      new Image().src = item.image;
    });
  }, []);

  const renderOverview = () => {
    if (isDesktop) {
      return (
        <Col mt={20}>
          <UiHeading fontSize="lg" lineHeight="3lg" fontWeight="bold" pb={4}>
            Simple application. Designed for busy bees.
          </UiHeading>
          <UiText color="secondary.text" fontSize={22} lineHeight="3sm">
            Organize your works in one single app. From tasks, assignments, events to house chores.
          </UiText>
          <Row gap={20} flex={1} py={20}>
            {WORKS.map((item, index) => (
              <Col
                key={item.id}
                w="373px"
                border="1px"
                borderStyle="solid"
                borderColor="#E0E0E0"
                borderRadius="8px"
                flex={1}
                boxShadow="rgba(0, 0, 0, 0.08) 0px 4px 12px"
              >
                <Row w="373px">
                  <LazyLoadImage
                    width="373px"
                    style={{ objectFit: "cover", borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}
                    src={item.image}
                    placeholderSrc={imagePlaceholder}
                    effect="blur"
                    visibleByDefault={index > 0}
                  />
                </Row>
                <Col mx={8} mt={5} mb={10}>
                  <UiText fontWeight="bold" fontSize="sm" lineHeight="3sm">
                    {item.title}
                  </UiText>
                  <UiText color="#8E8E8E" fontSize={16} lineHeight="2sm">
                    {item.content}
                  </UiText>
                </Col>
              </Col>
            ))}
          </Row>
        </Col>
      );
    }
    return (
      <Col mx={{ md: "5%" }} pb="10%" width="90vw">
        <Col textAlign="center">
          <MotionSlideFade>
            <UiHeading fontSize="lg" lineHeight="3lg" fontWeight="bold" pb={5}>
              Simple application. Designed for busy bees.
            </UiHeading>
            <UiText color="secondary.text" fontSize={{ base: "3xs", md: "sm" }} lineHeight="4xs">
              Organize your works in one single app. From tasks, assignments, events to house chores.
            </UiText>
          </MotionSlideFade>
        </Col>
        <Carousel responsive={responsive} arrows={false} centerMode={false} showDots infinite customDot={<CustomDot />}>
          {WORKS.map((item, index) => (
            <Col my={10} key={item.id} height="300%" alignItems="center">
              <Col
                mb={3}
                width="250px"
                border="1px"
                borderStyle="solid"
                borderColor="#E0E0E0"
                borderRadius="8px"
                boxShadow="rgba(0, 0, 0, 0.08) 0px 4px 12px"
              >
                <Row width="249px" alignSelf="center">
                  <LazyLoadImage
                    width="100%"
                    src={item.image}
                    placeholderSrc={imagePlaceholder}
                    style={{ objectFit: "cover", borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}
                    effect="blur"
                    visibleByDefault={index > 0}
                  />
                </Row>
                <Col mx={2} mt={5} mb={10}>
                  <UiText fontWeight="bold" fontSize={{ base: "3xs", md: "sm" }}>
                    {item.title}
                  </UiText>
                  <UiText color="#8E8E8E" fontSize={{ base: "2xs", md: "3xs" }}>
                    {item.content}
                  </UiText>
                </Col>
              </Col>
            </Col>
          ))}
        </Carousel>
      </Col>
    );
  };

  return (
    <UiBox mb="0px" id={SECTION_IDS.DOWNLOAD} pos="relative">
      <AppContainer h="100%" as={Col} justifyContent="center" maxW={sharedStyle.menuMaxW}>
        <Col>
          <Col marginTop={{ base: 25, lg: 20 }} textAlign="center" justifyContent="center">
            <MotionSlideFade>
              <UiText color="secondary.text" fontSize={{ base: "4xs", lg: 22 }}>
                This app is simple as the way teamwork should be. Download now:
              </UiText>
            </MotionSlideFade>
            <MotionSlideFade>
              <Row gap={7} pt={10} pb={10} justifyContent="center">
                <UiLink href={appStoreLink} isExternal>
                  <Row w={{ base: "120px", lg: "151.1px" }}>
                    <UiImage w="100%" src={appStore} />
                  </Row>
                </UiLink>
                <UiLink href={googlePlayLink} isExternal>
                  <Row w={{ base: "120px", lg: "151.1px" }}>
                    <UiImage w="100%" src={googlePlay} />
                  </Row>
                </UiLink>
              </Row>
            </MotionSlideFade>
          </Col>
          {renderOverview()}
        </Col>
      </AppContainer>
    </UiBox>
  );
};
export default OverviewTabs;
