import {
  imageCreateAndShareToDo,
  imageFollowUp,
  imageRemindersAndSmartSnoozes,
  phoneAssignAndSupervise,
  phoneCreateReminders,
  phoneQuickAndActions,
  phoneSmartsNooze,
} from "~/assets/img";

export const TABS = [
  {
    id: 1,
    name: "Create reminders",
    contentLeft: ["Create new reminders at a flash", "Personal & Team reminders supported"],
    contentRight: ["Hassle-free switching between personal space and team space", "On loop until task is updated"],
    phoneImage: phoneCreateReminders,
  },
  {
    id: 2,
    name: "Smart snooze",
    contentLeft: ["Never miss a thing with smart automatic snooze"],
    contentRight: ["Receive notifications on time even when offline"],
    phoneImage: phoneSmartsNooze,
  },
  {
    id: 3,
    name: "Quick & Bulk Actions",
    contentLeft: ["Update task by 1 touch - click & swipe"],
    contentRight: ["Super fast to select and update multiple tasks", "Smart snooze options for selected tasks"],
    phoneImage: phoneQuickAndActions,
  },
  {
    id: 4,
    name: "Assign and Supervise",
    contentLeft: ["Assign coworkers to reminders", "Supervise the reminders till the completion"],
    contentRight: ["Ask your coworkers on the task’s status", "Submit your task for supervisor’s review"],
    phoneImage: phoneAssignAndSupervise,
  },
];

export const WORKS = [
  {
    id: 1,
    title: "Easily create and share to-dos.",
    content: "Create new reminders at a flash. On loop until task is updated.",
    image: imageCreateAndShareToDo,
  },
  {
    id: 2,
    title: "Get reminders & smart snoozes.",
    content: "Receive notifications & snoozes on time even when offline.",
    image: imageRemindersAndSmartSnoozes,
  },
  {
    id: 3,
    title: "Follow up on the go",
    content: "Interact with your coworkers on the task’s status.",
    image: imageFollowUp,
  },
];
