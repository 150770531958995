import React from "react";
import { motion } from "framer-motion";

interface Props {
  children: React.ReactNode;
}

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { ease: "easeOut", duration: 1 } },
  hidden: { opacity: 0, y: "50%" },
};

export function MotionSlideFade({ children }: Props) {
  return (
    <motion.div
      initial="hidden"
      viewport={{ once: true }}
      whileInView="visible"
      variants={boxVariant}
    >
      {children}
    </motion.div>
  );
}
