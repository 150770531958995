import React, { memo } from "react";
import { BaseLayout } from "~/components/layouts";
import { useAppSelector, useBoolBag } from "~/hooks";
import { UiBox } from "~/components/elements";

interface Props {
  children: React.ReactNode;
}

export const PrivateLayout = memo(({ children }: Props) => {
  const { boolBag, setBoolBag } = useBoolBag({ showMobileMenu: false });
  const { showMobileMenu } = boolBag;

  const currentUser = useAppSelector((state) => state.auth.currentUser);

  // if (isEmpty(currentUser) && window.location.pathname !== '/p/login') {
  //   const redirectTo = stringify({
  //     redirect: window.location.href,
  //   });
  //   return <Redirect to={`/p/login?${redirectTo}`} />;
  // }

  // if (isMobile) {
  //   return (
  //     <BaseLayout>
  //       <UiIconButton
  //         onClick={() => setBoolBag({showMobileMenu: true})}
  //         aria-label="Show menu"
  //         icon={<UiIcon as={GiHamburgerMenu} boxSize={'8px'} />}
  //       />
  //       <UiDrawer isOpen={showMobileMenu} placement="left" onClose={() => setBoolBag({showMobileMenu: false})}>
  //         <UiDrawerOverlay />
  //         <UiDrawerContent>
  //           <UiDrawerCloseButton />
  //           <UiDrawerHeader>Create your account</UiDrawerHeader>
  //
  //           <UiDrawerBody>
  //             <UiInput placeholder="Type here..." />
  //             <Navigation />
  //             {children}
  //           </UiDrawerBody>
  //
  //           <UiDrawerFooter>
  //             <UiButton variant="outline" mr={3} onClick={() => setBoolBag({showMobileMenu: false})}>
  //               Cancel
  //             </UiButton>
  //             <UiButton>Save</UiButton>
  //           </UiDrawerFooter>
  //         </UiDrawerContent>
  //       </UiDrawer>
  //     </BaseLayout>
  //   );
  // }
  return (
    <BaseLayout>
      {/* <Navigation /> */}
      <UiBox>{children}</UiBox>
    </BaseLayout>
  );
});
