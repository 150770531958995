// eslint-disable-next-line no-restricted-imports
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import ReactGA from "react-ga4";
import { Provider } from "react-redux";
import { setup as setupError } from "ry-error-wrapper";
import "~/assets/scss/main.scss";
import { AppRouter, UiPageLoading } from "~/components/elements";
import { appConfig } from "~/config";
import config from "~/config/app-config";
import { PAGES } from "~/constants";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { onLoginSuccessMisc } from "~/redux/auth/thunk";
import rootStore from "~/redux/root-store";
import reportWebVitals from "~/reportWebVitals";
import { privateRoutes, publicRoutes } from "~/routes";
import { ignoreLogs } from "~/services/ignore-logs";
import { initSentry } from "~/services/sentry";
import Fonts from "~/theme/chakra-font";
import { uiTheme } from "~/theme/chakra-theme";
import * as storage from "~/utils/storage";
import { getCurrentUserFromStorage } from "./services/auth";

ignoreLogs();

initSentry();

setupError({ initSentry, getUser: async () => storage.load("SNAP_GEN_USER") });

ReactGA.initialize("G-9DK518N6L2");

function Root() {
  return (
    <Provider store={rootStore}>
      <GoogleOAuthProvider clientId={appConfig.googleClientId}>
        <ChakraProvider theme={uiTheme}>
          <Fonts />
          <App />
        </ChakraProvider>
      </GoogleOAuthProvider>
    </Provider>
  );
}

function App() {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(state => state.auth.currentUser);

  const [isInitializing, setIsInitializing] = useState(true);

  useEffect(() => {
    const init = async () => {
      const { token: savedToken, user: savedUser } = getCurrentUserFromStorage();
      if (savedToken) {
        await dispatch(onLoginSuccessMisc({ user: savedUser, token: savedToken }));
      }

      setIsInitializing(false);
    };
    init();
  }, [dispatch]);

  if (isInitializing) {
    return <UiPageLoading />;
  }

  return (
    <AppRouter
      basename={config.routeBaseName}
      authorities={currentUser?.roles}
      privateRoutes={privateRoutes}
      publicRoutes={publicRoutes}
      defaultRedirect={PAGES.LOGIN}
    />
  );
}

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <ColorModeScript initialColorMode={uiTheme.config.initialColorMode} />
    <Root />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
