import { Text, TextProps, useMediaQuery } from "@chakra-ui/react";
import React, { memo } from "react";
import isString from "lodash/isString";
import { QUERY_MOBILE } from "~/constants";

interface Props extends TextProps {}

const desktopToMobileSizeMap: Record<string, string> = {
  "6xl": "4xl",
  "5xl": "3xl",
  "4xl": "2xl",
  "3xl": "1xl",
  "2xl": "xl",
  "1xl": "lg",
  xl: "md",
  lg: "sm",
  md: "sm",
  sm: "sm",
};
export const UiText = memo(({ fontSize, ...rest }: Props) => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {
    ssr: false,
  });
  let fontSizeObj: any;
  if (!isDesktop && isString(fontSize)) {
    fontSizeObj = {
      base: desktopToMobileSizeMap[fontSize] || fontSize,
      sm: "sm",
      md: "md",
      lg: "lg",
      xl: "xl",
    };
  }

  return <Text fontSize={fontSizeObj || fontSize} {...rest} />;
});
