import React, { memo, useCallback, useState } from "react";
import Dropzone, { Accept } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "~/redux/root-store";
import { RootState } from "~/redux/root-reducer";
import { UiImage } from "~/components/elements/UiImage";
import { uploadFile } from "~/redux/apps/thunk";
import { UiIcon } from "~/components/elements/UiIcon";
import { FiUpload } from "react-icons/fi";
import {
  Row,
  UiBox,
  UiCenter,
  UiSpinner,
  UiText,
} from "~/components/elements/index";
import { CenterProps } from "@chakra-ui/react";
import { useNotification } from "~/hooks";

export interface UploadResponse {
  fileName: string;
  fileType: string;
  accessUrl: string;
}

type AcceptType = "VIDEO" | "PDF" | "IMAGE" | "AUDIO";

interface Props {
  type: string;
  placeholder?: string | null;
  contentProps?: CenterProps;
  onSuccess: (arg: UploadResponse[]) => any;
  acceptType?: AcceptType[];
  multiple?: boolean;
  preview?: boolean;
  endpoint?: string;
}

const getAcceptFileTypes = (types: AcceptType[]): Accept => {
  const map = {
    VIDEO: "video/*",
    AUDIO: "audio/*",
    IMAGE: "image/*",
    PDF: "application/pdf",
  };
  const accept = {} as Accept;
  types.forEach((type) => {
    if (map[type]) {
      accept[map[type]] = [];
    }
  });
  return accept;
};

const maxSize = 10485760; // 1048576 Bytes * 10 = 10M

const defaultContentProps = { py: 8, px: 5 };
export const AppDropzone = memo(
  ({
    type,
    onSuccess,
    preview = true,
    multiple = false,
    acceptType = ["PDF", "IMAGE", "VIDEO"],
    placeholder = "Drag and Drop / click to select file",
    contentProps = {},
    endpoint,
  }: Props) => {
    const dispatch: AppDispatch = useDispatch();
    const { addMessage } = useNotification(dispatch);
    const { loading } = useSelector(
      (state: RootState) => state.ui[uploadFile.typePrefix] || {}
    );

    const [uploadedFiles, setUploadedFiles] = useState<UploadResponse[]>([]);

    const onDrop = useCallback(
      async (acceptedFiles: string | any[]) => {
        if (!acceptedFiles || acceptedFiles.length === 0) {
          return;
        }
        let res: UploadResponse[] = [];

        for (let i = 0; i < acceptedFiles.length; i += 1) {
          const file = acceptedFiles[i];
          if (file.size > maxSize) {
            addMessage(
              "File is too large. Please upload file under 3MB",
              "error"
            );
            return;
          }
          // const resAction = await dispatch(
          // uploadFile({
          //   file,
          //   fileName: file.name,
          // type,
          // endpoint,
          // }),
          // );
          // if (requestOK(resAction, uploadFile)) {
          //   // @ts-ignore
          //   res.push({fileName: file.name, fileType: file.type, accessUrl: resAction?.payload?.accessUrl});
          // }
        }
        res = multiple ? [...(uploadedFiles || []), ...res] : res;
        setUploadedFiles(res);
        onSuccess(res);
      },
      [multiple, onSuccess, uploadedFiles, addMessage]
    );

    const onRemovePreview = (f: UploadResponse) => {
      setUploadedFiles(
        uploadedFiles.filter((file) => file.accessUrl !== f.accessUrl)
      );
    };

    const renderPreview = () => {
      if (!preview || !uploadedFiles || uploadedFiles.length === 0) {
        return null;
      }
      return (
        <Row flexDirection="row" flexWrap="wrap" mt={3}>
          {uploadedFiles?.map((f: UploadResponse, i) => {
            if (!f.fileType.includes("image")) {
              return null;
            }
            return (
              <UiImage
                boxSize="100px"
                src={f.accessUrl}
                containerClasses="mb-2"
                key={`image-index-${f.accessUrl}`}
                onClose={() => onRemovePreview(f)}
                mr="2"
                objectFit="cover"
              />
            );
          })}
        </Row>
      );
    };

    const renderPlaceholder = () => {
      if (placeholder === null) {
        return null;
      }
      return (
        <UiText fontSize="md" fontWeight="semibold">
          {placeholder}
        </UiText>
      );
    };

    return (
      <UiBox cursor="pointer">
        <Dropzone onDrop={onDrop} accept={getAcceptFileTypes(acceptType)}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <UiCenter
                borderWidth={1}
                {...{ ...defaultContentProps, ...contentProps }}
              >
                {loading && <UiSpinner size="lg" />}
                <UiIcon as={FiUpload} boxSize="25px" />
                {renderPlaceholder()}
                <input {...getInputProps()} />
              </UiCenter>
            </div>
          )}
        </Dropzone>
        {renderPreview()}
      </UiBox>
    );
  }
);
