import React from "react";
import { autoSnooze, vectorBrief } from "~/assets/img";
import { AppContainer, Col, Row, UiBox, UiHeading, UiImage, UiListItem, UiText, UiUnorderedList, useMediaQuery } from "~/components/elements";
import { QUERY_MOBILE } from "~/constants";
import { sharedStyle } from "~/theme/shared-style";
import { MotionSlideFade } from "~/components/shared/MotionSlideFade";

const HighlightText = ({ children }: { children: string }) => (
  <UiText fontSize={{ base: "3xs", lg: "4xs" }} as="span" fontWeight="bold" color="main.primary">
    {children}
  </UiText>
);

const KeyFeatureItem = ({ children, color = "black" }: { children: React.ReactNode; color?: string }) => (
  <UiListItem textColor="black" color={color} fontSize={{ base: "3xs", lg: "4xs" }} fontWeight="bold">
    {children}
  </UiListItem>
);

const KeyFeature = () => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {
    ssr: false,
  });

  return (
    <UiBox mb="0px" pos="relative">
      <AppContainer h="100%" as={Row} justifyContent="center" alignItems="center" maxW={sharedStyle.menuMaxW}>
        <UiBox flex={1} mx={{ base: "5%" }} as={Col} alignItems="center" mb={100} mt={{ base: 65, lg: 170 }}>
          <MotionSlideFade>
            <Col textAlign="center">
              <UiHeading fontSize="lg" fontWeight="bold">
                Simple app that covers
              </UiHeading>
            </Col>
            <Col ml={{ base: 20, md: 3, lg: 3 }} mt={{ base: "-7px", md: 2, lg: 0 }} w={{ base: "200px", md: "250px", lg: "277px" }}>
              <UiImage w="100%" src={vectorBrief} />
            </Col>
          </MotionSlideFade>
          <MotionSlideFade>
            <Row mt={20} height={{ base: "220px", md: "380px", lg: "523px" }} w={{ base: "300px", md: "504px", lg: "704px" }}>
              <UiImage w="100%" src={autoSnooze} />
            </Row>
          </MotionSlideFade>

          <UiBox as={isDesktop ? Row : Col} gap={{ lg: 80 }}>
            <MotionSlideFade>
              <UiUnorderedList lineHeight={{ base: "2sm", lg: "4sm" }}>
                <KeyFeatureItem>
                  Manage tasks with <HighlightText>reminders</HighlightText>
                </KeyFeatureItem>
                <KeyFeatureItem>
                  Never miss a thing <HighlightText>even when offline</HighlightText>
                </KeyFeatureItem>
                <KeyFeatureItem>
                  Smart <HighlightText>auto repeats</HighlightText> until tasks updated
                </KeyFeatureItem>
              </UiUnorderedList>
            </MotionSlideFade>
            <MotionSlideFade>
              <UiUnorderedList lineHeight={{ base: "2sm", lg: "4sm" }}>
                <KeyFeatureItem color="main.primary">
                  <HighlightText>Easy </HighlightText>
                  <UiText as="span" fontSize={{ base: "3xs", lg: "4xs" }}>
                    to use; learn it{" "}
                  </UiText>
                  <HighlightText> in 10 minutes</HighlightText>
                </KeyFeatureItem>
                <KeyFeatureItem color="main.primary">
                  <UiText as="span" fontSize={{ base: "3xs", lg: "4xs" }}>
                    Update tasks with{" "}
                  </UiText>
                  <HighlightText> quick action buttons</HighlightText>
                </KeyFeatureItem>
                <KeyFeatureItem color="main.primary">
                  <HighlightText> Super-convenient</HighlightText>{" "}
                  <UiText as="span" fontSize={{ base: "3xs", lg: "4xs" }}>
                    to schedule and reschedule
                  </UiText>
                </KeyFeatureItem>
              </UiUnorderedList>
            </MotionSlideFade>
          </UiBox>
        </UiBox>
      </AppContainer>
    </UiBox>
  );
};
export default KeyFeature;
