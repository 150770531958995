import { BaseEntityConfig, WhiteListFields } from "~/redux/base-entity-config";
import { AuthEntity } from "~/types/models/auth";

class EntityConfig extends BaseEntityConfig<AuthEntity> {
  entityKey = "auths";

  associates = [];

  whiteListFields: WhiteListFields<AuthEntity> = ["*"];

  getAccessors = (value: AuthEntity) => {
    //
  };
}

const authEntConf = new EntityConfig();

export const authSchema = authEntConf.getEntitySchema();

export default authEntConf;

export const SOURCES_AUTH = {};
