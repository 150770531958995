import {
  useAppDispatch,
  useInput,
  useNotification,
  useRequestState,
} from "~/hooks";
import { Link, useHistory } from "react-router-dom";
import { loginByEmailMisc } from "~/redux/auth/thunk";
import React from "react";
import { requestOK } from "redux-thunk-kit";
import {
  AppMessage,
  Col,
  Row,
  UiBox,
  UiInput,
  UiLink,
  UiPrimaryButton,
  UiText,
} from "~/components/elements";
import { DashboardLayout } from "~/components/layouts/DashboardLayout";
import { PageLayout } from "~/components/layouts/PageLayout";
import { PAGES } from "~/constants";

export default function RegisterPage() {
  const dispatch = useAppDispatch();
  const { addMessage } = useNotification(dispatch);
  const history = useHistory();
  const rules = {
    email: { email: true },
    password: { presence: true },
  };
  const [inputs, setInputs, { validation }] = useInput(
    { email: "", password: "" },
    { rules }
  );

  const { loading } = useRequestState(loginByEmailMisc);

  const handleSubmit = async (e: React.FormEvent) => {
    e?.preventDefault();
    if (validation.getErrors()) {
      return;
    }

    const resAction = await dispatch(loginByEmailMisc({ data: { ...inputs } }));
    if (requestOK(resAction, loginByEmailMisc)) {
      onSignUpSuccess();
    }
  };

  const onSignUpSuccess = () => {
    history.replace("/login");
  };

  return (
    <PageLayout
      metaTagsConfig={{
        metas: [{ name: "robots", content: "noindex,nofollow" }],
      }}
    >
      <DashboardLayout rightColProps={{ alignItems: "center" }}>
        <Col w="70%" pt="50px">
          <UiText
            textAlign="center"
            fontSize="13px"
            fontWeight="bold"
            mb="30px"
          >
            Create an account
          </UiText>
          <form onSubmit={handleSubmit}>
            <Col mb={3} px={4} w="85%" mx="auto">
              <UiBox mb={4}>
                <UiText>Email</UiText>
                <UiInput
                  size="sm"
                  name="email"
                  type="email"
                  defaultValue={inputs.email}
                  onChange={({ target }) => setInputs({ email: target.value })}
                />
              </UiBox>
              <UiBox mb={3}>
                <UiText>Password</UiText>
                <UiInput
                  w="100%"
                  size="sm"
                  name="password"
                  type="password"
                  defaultValue={inputs.password}
                  onChange={({ target }) =>
                    setInputs({ password: target.value })
                  }
                />
              </UiBox>
              <Row justifyContent="space-between">
                <UiBox>
                  <UiPrimaryButton bg="main.primary" size="xs" color="black">
                    Sign up
                  </UiPrimaryButton>
                </UiBox>

                <UiLink
                  as={Link}
                  to="/abc"
                  alignSelf="flex-end"
                  color="main.600"
                >
                  <UiText fontSize="10px">Forgot password</UiText>
                </UiLink>
              </Row>
            </Col>
          </form>
          <AppMessage body={validation.errors} />
          <UiText mt={6} fontSize="10px">
            Already have an account? Click{" "}
            <UiLink as={Link} to={PAGES.LOGIN}>
              &nbsp;here&nbsp;
            </UiLink>{" "}
            to login instead
          </UiText>
        </Col>
      </DashboardLayout>
    </PageLayout>
  );
}
