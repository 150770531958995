import request from "~/utils/request";
import { AppError } from "ry-error-wrapper";
import { data } from "~/dummy-data";

export default {
  apiServices: [
    { name: "main", axios: request, isDefault: true, isSnakeCase: true },
  ],
  errorHandler: AppError,
  dummyData: data,
};
