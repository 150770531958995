import React from "react";
import { Global } from "@emotion/react";

function Fonts() {
  return (
    <Global
      styles={`
      @font-face {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: normal;
        src: url('/fonts/montserrat/Montserrat-Regular.ttf') format('truetype');
        font-display: swap;
      } 
      @font-face {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: bold;
        src: url('/fonts/montserrat/Montserrat-Bold.ttf') format('truetype');
        font-display: swap;
      }
      @font-face {
        font-family: 'SecularOne';
        font-style: normal;
        font-weight: normal;
        src: url('/fonts/secular-one/SecularOne-Regular.ttf') format('truetype');
        font-display: swap;
      }  
      @font-face {
        font-family: 'PublicSans';
        font-style: normal;
        font-weight: normal;
        src: url('/fonts/public-sans/PublicSans-VariableFont_wght.ttf') format('truetype');
        font-display: swap;
      }  
      @font-face {
        font-family: 'Inter';
        font-style: normal;
        font-weight: normal;
        src: url('/fonts/inter/InterVariable.ttf') format('truetype');
        font-display: swap;
      } 
      @font-face {
        font-family: 'Inter';
        font-style: normal;
        font-weight: bold;
        src: url('/fonts/inter/Inter-Bold.ttf') format('truetype');
        font-display: swap;
      } 
      `}
    />
  );
}

export default Fonts;
