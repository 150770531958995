import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import mapValues from "lodash/mapValues";
import { UserEntity } from "~/types/models/user";
import { RootState } from "~/redux/root-reducer";
import {
  createSelectAllBySource,
  DEFAULT_SOURCE_REDUCER_STATE,
  ReducerState,
} from "redux-thunk-kit";
import { SOURCES_USER } from "~/redux/users/entity-config";

/* Slice */
interface InitialState extends ReducerState {}

const sources = mapValues(SOURCES_USER, () => DEFAULT_SOURCE_REDUCER_STATE);

const usersAdapter = createEntityAdapter<UserEntity>();

const initialState = usersAdapter.getInitialState<InitialState>({
  sources,
  request: {},
  token: undefined,
});

// Slice
const users = createSlice({
  name: "users",
  initialState,
  reducers: {
    setCurrentUser(state, { payload }: PayloadAction<UserEntity>) {
      state.token = payload.token;
    },
  },
  extraReducers: (builder) => {},
});

export const { setCurrentUser } = users.actions;

export default users.reducer;

export const {
  selectById: selectUserById,
  selectIds: selectUsersIds,
  selectEntities: selectUsersEntities,
  selectAll: selectAllUsers,
  selectTotal: selectTotalUsers,
} = usersAdapter.getSelectors((state: RootState) => state.users);

export const [getUsers, getDropdownUsers] = createSelectAllBySource(
  "users",
  selectUsersEntities,
  [SOURCES_USER.USERS_PAGE, SOURCES_USER.USERS_DROPDOWN]
);
