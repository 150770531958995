import React, { memo } from "react";
import { ChakraStylesConfig, Select } from "chakra-react-select";
import { InputActionMeta, Props as SelectProps } from "react-select";
import {
  Box,
  StylesProvider,
  useMultiStyleConfig,
  useTheme,
} from "@chakra-ui/react";

export declare type Size = "sm" | "md" | "lg";
export declare type TagVariant = "subtle" | "solid" | "outline" | undefined;
export declare type SelectedOptionStyle = "color" | "check";
export type SizeProps = {
  sm: string | number;
  md: string | number;
  lg: string | number;
};

export interface UiDropdownProps extends SelectProps {
  size?: Size;
  minW?: string;
  colorScheme?: string;
  isInvalid?: boolean;
  tagVariant?: TagVariant;
  hasStickyGroupHeaders?: boolean;
  selectedOptionStyle?: SelectedOptionStyle;
  selectedOptionColor?: string;
  multiple?: boolean;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  menuWidth?: string;
}

function Menu({ children, w, innerProps, selectProps: { size } }: any) {
  const menuStyles = useMultiStyleConfig("Menu", {});
  const chakraTheme = useTheme();
  const borderRadii: SizeProps = {
    sm: chakraTheme.radii.sm,
    md: chakraTheme.radii.md,
    lg: chakraTheme.radii.md,
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: "100%",
        my: "8px",
        w,
        zIndex: 1,
        overflowY: "auto",
        overflowX: "hidden",
        rounded: borderRadii[size as Size],
      }}
      {...innerProps}
    >
      <StylesProvider value={menuStyles}>{children}</StylesProvider>
    </Box>
  );
}

export const UiDropdown = memo(
  React.forwardRef(
    (
      {
        multiple = false,
        menuWidth = "100%",
        components = {},
        size = "sm",
        minW,
        onInputChange,
        ...rest
      }: UiDropdownProps,
      ref
    ) => {
      const chakraStyles: ChakraStylesConfig = {
        control: (provided: any, state: any) => ({
          ...provided,
          minWidth: minW || "8em",
          fontWeight: "normal",
          textTransform: "none",
        }),
        menuList: (provided: any, state: any) => ({
          ...provided,
          fontWeight: "normal",
          textTransform: "none",
          overflowX: "hidden",
        }),
      };

      return (
        <Select
          // @ts-ignore
          ref={ref}
          size={size}
          isMulti={multiple}
          // menuIsOpen // Use this one to debug
          components={{
            // eslint-disable-next-line react/no-unstable-nested-components
            Menu: (props: any) => <Menu w={menuWidth} {...props} />,
            ...components,
          }}
          chakraStyles={chakraStyles}
          onInputChange={onInputChange}
          {...rest}
        />
      );
    }
  )
);
