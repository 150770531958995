import * as storage from "~/utils/storage";
import { AuthToken } from "~/types/models/auth";

interface UserCredential {
  user?: any;
  accessToken?: string | null;
  token?: AuthToken;
}

interface GoogleUserInfo {
  accessToken?: string;
  user?: any;
}

export const authWithGoogle = async () => {
  // try {
  //   const resp = await request.get(API_URL.GET_GOOGLE_AUTH_URL)
  //   const googleAuthURL = resp.data.data.url
  //   window.location = googleAuthURL
  // } catch (e) {
  //   new AppError(e, 'Cannot login using google. Please try again')
  // }
};

export const removeSavedUserInfo = () => {
  storage.remove("SNAP_GEN_ACCESS_TOKEN");
  storage.remove("SNAP_GEN_USER");
};

export const saveUserCredential = async ({
  accessToken,
  user,
}: UserCredential) => {
  if (accessToken) {
    await storage.save("SNAP_GEN_ACCESS_TOKEN", accessToken);
  }
  if (user) {
    await storage.save("SNAP_GEN_USER", user);
  }
};

export const saveSessionIdUser = async (sessionId: string) => {
  if (sessionId) {
    await storage.save("SNAP_GEN_SESSION_ID_USER", sessionId);
    await storage.save("SNAP_GEN_SESSION_ID_USER", sessionId, "cookie");
  }
};

export const getCurrentUserFromStorage = (): UserCredential => {
  const user = storage.load("SNAP_GEN_USER");
  const accessToken = storage.load("SNAP_GEN_ACCESS_TOKEN");

  return {
    accessToken,
    user,
  };
};

export const getSessionIdUserFromStorage = () => {
  let sessionId = storage.load("SNAP_GEN_SESSION_ID_USER");
  if (!sessionId) {
    sessionId = storage.load("SNAP_GEN_SESSION_ID_USER", "cookie");
  }

  return { sessionId };
};
