import React, { memo } from "react";
import { useMediaQuery } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { AppContainer, Col, Row, UiBox, UiDivider, UiIcon, UiImage, UiLink, UiText } from "~/components/elements/index";
import { PAGES, QUERY_MOBILE, appStoreLink, googlePlayLink } from "~/constants";
import moment from "moment";
import { appStore, googlePlay } from "~/assets/img";
import { FOOTER_MENU, FOOTER_SOCIALS } from "~/constants/footer";

export const Footer = memo(
  React.forwardRef<HTMLDivElement>((props, ref) => {
    const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {
      ssr: false,
    });

    const renderLinkImage = (href: string, src: string) => (
      <UiLink href={href} isExternal>
        <Row w={{ base: "120px", md: "153px" }}>
          <UiImage borderRadius={5} w="100%" src={src} />
        </Row>
      </UiLink>
    );

    return (
      <UiBox ref={ref} bgColor="main.background" p={{ base: "10px 0px" }}>
        <AppContainer h="100%" color="white" w={{ base: "90%", lg: "100%" }}>
          <UiBox as={isDesktop ? Row : Col} justifyContent="space-between" mx={{ lg: 15 }} marginY={20} alignItems={{ md: "flex-start" }}>
            <Col mb={{ base: 10, lg: 0 }} id="joinbeta">
              <UiText mb={5} color="secondary.text" fontSize={{ base: "size16", md: "size16" }}>
                Find us on:
              </UiText>
              <Row marginBottom={{ base: 4 }} gap={8}>
                {renderLinkImage(appStoreLink, appStore)}
                {renderLinkImage(googlePlayLink, googlePlay)}
              </Row>
            </Col>
            <Row width={{ base: "100%", lg: "36%" }} justifyContent={{ base: "unset", lg: "space-between" }}>
              {FOOTER_MENU.map(menuItem => (
                <Col key={menuItem.key} mr={{ base: "4rem", lg: 0 }}>
                  <UiText mb={5} color="secondary.text" fontSize={{ base: "3xs", md: "size16" }}>
                    {menuItem.title}
                  </UiText>
                  {menuItem.pages.map(page => (
                    <UiLink href={page.url}>
                      <UiText mb={5} color="main.900" fontSize={{ base: "2xs", md: "size16" }} key={page.key}>
                        {page.title}
                      </UiText>
                    </UiLink>
                  ))}
                </Col>
              ))}
            </Row>
          </UiBox>
          <UiDivider borderColor="grey.200" borderBottomWidth="1px" mt="20px" />
          <UiBox
            as={isDesktop ? Row : Col}
            gap={3}
            color="white"
            my={{ base: "10px", lg: "35px" }}
            justifyContent="space-between"
            alignItems={{ base: "unset", lg: "center" }}
          >
            <Row gap={10} alignItems="center" flexWrap="wrap">
              <UiText fontSize={{ base: "3xs", md: "size16" }} color="secondary.text">
                Copyright © {moment().year()} Mr Thing. All rights reserved.
              </UiText>
              <UiLink as={Link} to={PAGES.POLICY} fontSize={{ base: "3xs", md: "size16" }} color="secondary.text">
                Privacy
              </UiLink>
              {/* <UiLink as={Link} to={PAGES.POLICY} fontSize={{ base: "3xs", md: "size16" }} color="secondary.text">
                Legal & security
              </UiLink> */}
            </Row>
            <Row gap={5} mt={{ base: 10, lg: 0 }} alignItems="center">
              {FOOTER_SOCIALS.map(social => (
                <UiLink key={social.key} href={social.url} display="flex" alignItems="center" justifyContent="center">
                  <UiIcon as={social.icon} color="main.primary" boxSize={6} />
                </UiLink>
              ))}
            </Row>
          </UiBox>
        </AppContainer>
      </UiBox>
    );
  }),
);
