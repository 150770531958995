import React from "react";
import { UiBox } from "~/components/elements";
import useMetaTags, { MetaTagsConfig } from "react-metatags-hook";
import { BoxProps } from "@chakra-ui/react";

interface Props extends BoxProps {
  children: React.ReactNode;
  metaTagsConfig: MetaTagsConfig;
}

export function PageLayout({ metaTagsConfig, children, ...rest }: Props) {
  useMetaTags(metaTagsConfig, []);

  return <UiBox {...rest}>{children}</UiBox>;
}
