import { Button, ButtonProps } from "@chakra-ui/react";
import React, { memo } from "react";
import { useDelay } from "~/hooks";

interface Props extends ButtonProps {
  title?: string;
}

export const UiButton = memo(
  React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ title, children, onClick, ...rest }: Props, ref) => {
      const bntTitle = title || children;
      const { startDelay, isDelaying } = useDelay(500);

      const handleClick = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
      ) => {
        if (onClick && !isDelaying) {
          onClick(e);
          startDelay();
        }
      };

      return (
        <Button ref={ref} onClick={handleClick} {...rest}>
          {bntTitle}
        </Button>
      );
    }
  )
);

export function UiPrimaryButton({ bg = "black", ...rest }: ButtonProps) {
  return <UiButton variant="solid" bg={bg} {...rest} />;
}
