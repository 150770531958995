import {
  useAppDispatch,
  useInput,
  useNotification,
  useRequestState,
} from "~/hooks";
import { Link, useHistory } from "react-router-dom";
import { loginByEmailMisc, socialLoginMisc } from "~/redux/auth/thunk";
import React from "react";
import { requestOK } from "redux-thunk-kit";
import {
  AppMessage,
  Col,
  Row,
  UiBox,
  UiInput,
  UiLink,
  UiPrimaryButton,
  UiText,
} from "~/components/elements";
import { getPageQuery } from "~/utils/helpers";
import { GoogleCredentialResponse } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { SOCIAL_LOGIN_PLATFORMS } from "~/constants";
import { appConfig } from "~/config";
import { DashboardLayout } from "~/components/layouts/DashboardLayout";
import { PageLayout } from "~/components/layouts/PageLayout";

export default function LoginPage() {
  const dispatch = useAppDispatch();
  const { addMessage } = useNotification(dispatch);
  const history = useHistory();
  const rules = {
    email: { email: true },
    password: { presence: true },
  };
  const [inputs, setInputs, { validation }] = useInput(
    { email: "", password: "", activeTabIdx: 0 },
    { rules }
  );

  const { loading } = useRequestState(loginByEmailMisc);

  const onLoginSuccess = (redirectAfterLogin?: string) => {
    // Try to redirect to the very first URL
    const urlParams = new URL(window.location.href);
    const queryParams = getPageQuery();
    let { redirect } = queryParams as { redirect: string };
    if (redirectAfterLogin) {
      history.replace(redirectAfterLogin);
      return;
    }
    if (redirect) {
      const redirectUrlParams = new URL(redirect);
      if (redirectUrlParams.origin === urlParams.origin) {
        redirect = redirect.substr(urlParams.origin.length);
        if (redirect.match(/^\/.*#/)) {
          redirect = redirect.substr(redirect.indexOf("#") + 1);
        }
      } else {
        window.location.href = "/";
        return;
      }
    }
    history.replace(redirect || "/");
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e?.preventDefault();
    if (validation.getErrors()) {
      return;
    }

    const resAction = await dispatch(loginByEmailMisc({ data: { ...inputs } }));
    if (requestOK(resAction, loginByEmailMisc)) {
      onLoginSuccess();
    }
  };

  const onLoginGoogleSuccess = async (resp: GoogleCredentialResponse) => {
    const { credential } = resp;

    const profileObj: any = jwt_decode(credential || "");

    const resAction = await dispatch(
      socialLoginMisc({
        data: {
          token: credential || "",
          provider: SOCIAL_LOGIN_PLATFORMS.GOOGLE,
          clientId: appConfig.googleClientId,
          profilePicture: profileObj.picture,
          name: profileObj.name,
        },
      })
    );
    if (requestOK(resAction, socialLoginMisc)) {
      onLoginSuccess();
    }
  };

  // const onLoginGoogleFailure = useGoogleLogin({
  //   onError: errorResponse => addMessage(errorResponse.error_description || '', 'error'),
  // });

  return (
    <PageLayout
      metaTagsConfig={{
        metas: [{ name: "robots", content: "noindex,nofollow" }],
      }}
    >
      <DashboardLayout rightColProps={{ alignItems: "center" }}>
        <Col w="70%" pt="50px">
          <UiText
            textAlign="center"
            fontSize="13px"
            fontWeight="bold"
            mb="30px"
          >
            Sign in your account
          </UiText>
          <form onSubmit={handleSubmit}>
            <Col mb={3} px={4} w="85%" mx="auto">
              <UiBox mb={4}>
                <UiText>Email</UiText>
                <UiInput
                  size="sm"
                  name="email"
                  type="email"
                  defaultValue={inputs.email}
                  onChange={({ target }) => setInputs({ email: target.value })}
                />
              </UiBox>
              <UiBox mb={3}>
                <UiText>Password</UiText>
                <UiInput
                  w="100%"
                  size="sm"
                  name="password"
                  type="password"
                  defaultValue={inputs.password}
                  onChange={({ target }) =>
                    setInputs({ password: target.value })
                  }
                />
              </UiBox>
              <Row justifyContent="space-between">
                <UiBox>
                  <UiPrimaryButton bg="main.primary" size="xs" color="black">
                    Sign in
                  </UiPrimaryButton>
                </UiBox>

                <UiLink
                  as={Link}
                  to="/abc"
                  alignSelf="flex-end"
                  color="main.600"
                >
                  <UiText fontSize="10px">Forgot password</UiText>
                </UiLink>
              </Row>
            </Col>
          </form>
          <AppMessage body={validation.errors} />
          <UiText mt={6} fontSize="10px">
            Already have an account? Click here to login instead
          </UiText>
        </Col>
      </DashboardLayout>
    </PageLayout>
  );
}
