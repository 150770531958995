import React, { memo, useEffect, useRef } from "react";
import { UiBox } from "~/components/elements";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import { BaseLayout, Footer, MainNavigation, TitleBar } from "~/components/layouts/";

interface Props {
  children: React.ReactNode;
}

export const PublicLayout = memo(({ children }: Props) => {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    if (hash === "") {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
          });
        }
      }, 300);
    }
  }, [pathname, hash, key]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: pathname,
    });
  }, [pathname]);

  return (
    <BaseLayout>
      <MainNavigation />
      <UiBox minH="100vh">{children}</UiBox>
      <Footer />
    </BaseLayout>
  );
});
