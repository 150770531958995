import React from "react";
import {
  AppAvatar,
  Col,
  ColProps,
  Row,
  UiAvatarGroup,
  UiBox,
  UiCenter,
  UiImage,
  UiText,
} from "~/components/elements";
import { BoxProps } from "@chakra-ui/react";
import logo from "~/assets/img/logo.svg";
import { appConfig } from "~/config";
import placeholder from "~/assets/img/noimage-placeholder.svg";
import rio1 from "~/assets/img/people/rio1.png";

interface Props extends BoxProps {
  rightColProps?: ColProps;
  children: React.ReactNode;
}

export const dashboardStyle = {
  boxHeight: "543",
  boxWidth: "821px",
};

export function DashboardLayout({ children, rightColProps, ...rest }: Props) {
  return (
    <UiCenter h="100vh" bg="#F0F5F6" {...rest} borderRadius="10px">
      <Row
        borderRadius="10px"
        w={dashboardStyle.boxWidth}
        h={dashboardStyle.boxHeight}
        bg="white"
        pt="19px"
        pb="28px"
        pl="22px"
        pr="76px"
      >
        <Col
          borderRadius="10px"
          bg="main.primary"
          h="100%"
          w="256px"
          pl="25px"
          pr="18px"
          pb="25px"
          pt="34px"
        >
          <Row align="center" mb="26px">
            <UiImage src={logo} width="40px" />
            <UiText fontWeight="bold">{appConfig.siteName}</UiText>
          </Row>
          <Col pos="relative" alignItems="center" h="186px" w="213px">
            <UiImage src={placeholder} h="186px" w="213px" />
            <UiAvatarGroup position="relative" bottom="20px">
              <AppAvatar
                src={rio1}
                height="35px"
                width="35px"
                borderWidth={0.3}
              />
              <AppAvatar
                src={rio1}
                height="35px"
                width="35px"
                borderWidth={0.3}
                zIndex={1}
              />
              <AppAvatar
                src={rio1}
                height="35px"
                width="35px"
                borderWidth={0.3}
                zIndex={2}
              />
            </UiAvatarGroup>
          </Col>
          <Col
            h="172px"
            justifyContent="center"
            borderRadius="10px"
            bg="#7297A7"
            w="100%"
            pl="24px"
            pr="11px"
            mt="auto"
          >
            <Row alignItems="center">
              <UiImage filePath="star.svg" h="18px" />
              <UiImage filePath="star.svg" h="18px" />
              <UiImage filePath="star.svg" h="18px" />
              <UiImage filePath="star.svg" h="18px" />
              <UiImage filePath="star.svg" h="18px" />
            </Row>
            <UiText color="white" mt={4} mb={3}>
              &quot;Simply unbelievable product. Really captures the best in me.
              with my friends&quot;
            </UiText>
            <Row align="center">
              <AppAvatar
                src={rio1}
                height="35px"
                width="35px"
                borderWidth={0.3}
              />
              <Col ml={3}>
                <UiText color="white">Timson K</UiText>
                <UiText color="white">Founder</UiText>
              </Col>
            </Row>
          </Col>
        </Col>
        <Col ml="77px" w="100%" {...(rightColProps || {})}>
          {children}
        </Col>
      </Row>
    </UiCenter>
  );
}
