import env from "./env";

export type AppStages = "development" | "staging" | "production";

interface AppConfig {
  siteName: string;
  stage: AppStages;
  sentry: { isEnable: boolean; dsn: string };
  api: {
    host: string;
    url: string;
    timeout: number;
  };
  googleClientId: string;
  url: string;
  [key: string]: any;
}

const { STAGE } = env as { STAGE: AppStages };
const config: AppConfig = {
  siteName: "Mr Thing",
  stage: STAGE,
  sentry: { isEnable: env.SENTRY_ENABLED, dsn: env.SENTRY_DSN },
  api: {
    host: env.API.HOST,
    url: `${env.API.HOST}/api/v1`,
    timeout: env.API.TIMEOUT,
  },
  url: env.URL,
  googleClientId: env.GOOGLE_CLIENT_ID,
};

if (STAGE === "staging") {
}
if (STAGE === "production") {
}

export default config;
