import React, { Suspense, useEffect, useRef } from "react";
import { PageLayout } from "~/components/layouts/PageLayout";
import { appConfig } from "~/config";
import { useLocation } from "react-router-dom";
import Banner from "~/pages/home-page/components/Banner";
import MarqueeText from "~/pages/home-page/components/MarqueeText";
import TopFeature from "~/pages/home-page/components/TopFeature";
import Session from "~/pages/home-page/components/Session";
import KeyFeature from "~/pages/home-page/components/KeyFeature";
import JoinNow from "~/pages/home-page/components/JoinNow";
import Brief from "~/pages/home-page/components/Brief";
import OverviewTabs from "~/pages/home-page/components/OverviewTabs";

function HomePage() {
  return (
    <PageLayout metaTagsConfig={{ title: appConfig.siteName }}>
      <Banner />
      <OverviewTabs />
      <TopFeature />
      <KeyFeature />
    </PageLayout>
  );
}

export default HomePage;
