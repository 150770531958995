import React from "react";
import {
  AppContainer,
  Col,
  UiBox,
  UiFormControl,
  UiFormHelperText,
  UiInput,
  UiLink,
  UiText,
  useMediaQuery,
  UiTextarea,
  UiHeading,
} from "~/components/elements";
import { Link } from "react-router-dom";
import { PAGES, QUERY_MOBILE } from "~/constants";
import { SendContactMessage } from "~/components/shared/SendContactMessage";
import { useInput } from "~/hooks";

function Banner() {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {
    ssr: false,
  });

  const rules = {
    email: { email: { message: "^Please enter a valid email address" } },
    name: { presence: { message: "^Please tell us your name" } },
    message: { presence: { message: "^Hello, how can we help you?" } },
  };

  const [inputs, setInputs, { validation, resetInput }] = useInput({ name: "", email: "", message: "" }, { rules });
  const onClickSendContactBtn = async () => {
    const validationErrors = validation.getErrors();
    if (validationErrors) {
      return;
    }
    resetInput();
  };

  return (
    <UiBox mb={{ base: "120px", lg: "50px" }} mt={{ base: "100px", lg: "180px" }}>
      <AppContainer h="100%" w={{ base: "90%", lg: "52%" }} mr="auto" ml="auto" pos="relative">
        <Col textAlign="center">
          <UiHeading mb={2} fontSize={{ base: "4sm", lg: "lg" }}>
            Contact Us
          </UiHeading>
          <UiText fontSize={{ base: "3xs", lg: "sm" }}>We are happy to assist you with any questions you may have about MrThing.</UiText>
        </Col>
        <Col mt="40px">
          <UiFormControl>
            <UiTextarea
              placeholder="Message"
              mb="10px"
              px={5}
              rows={9}
              color="black"
              borderRadius="20px"
              borderColor="black"
              _placeholder={{ color: "secondary.text" }}
              value={inputs.message}
              onChange={e => setInputs({ message: e.target.value })}
            />
            {validation?.errors?.message && (
              <UiFormHelperText mt="0px" mb="10px" color="red" fontSize="16px">
                {validation.errors.message[0]}
              </UiFormHelperText>
            )}
          </UiFormControl>
          <UiFormControl>
            <UiInput
              placeholder="Name"
              mb="10px"
              py={7}
              px={5}
              outline="0px"
              color="black"
              borderRadius="20px"
              border="1px solid #000000"
              _placeholder={{ color: "secondary.text" }}
              value={inputs.name}
              onChange={e => setInputs({ name: e.target.value })}
            />
            {validation?.errors?.name && (
              <UiFormHelperText mt="0px" mb="10px" color="red" fontSize="16px">
                {validation.errors.name[0]}
              </UiFormHelperText>
            )}
          </UiFormControl>
          <UiFormControl>
            <UiInput
              placeholder="Email"
              mb="10px"
              py={7}
              px={5}
              color="black"
              borderRadius="20px"
              border="1px solid #000000"
              borderColor="black"
              _placeholder={{ color: "secondary.text" }}
              value={inputs.email}
              onChange={e => setInputs({ email: e.target.value })}
            />
            {validation?.errors?.email && (
              <UiFormHelperText mt="0px" mb="10px" color="red" fontSize="16px">
                {validation.errors.email[0]}
              </UiFormHelperText>
            )}
          </UiFormControl>
          <UiText my="10px" fontSize={{ base: "3xs", lg: "4xs" }} lineHeight="sm">
            Your data collected in this form will be used only to contact you and talk about your project. For more information, see our{" "}
            <UiLink as={Link} to={PAGES.POLICY} color="main.primary">
              Privacy Policy.
            </UiLink>
          </UiText>
          <SendContactMessage loading={false} onClickSendContactBtn={onClickSendContactBtn} />
        </Col>
      </AppContainer>
    </UiBox>
  );
}

export default Banner;
