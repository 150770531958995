export const SOCIAL_LOGIN_PLATFORMS = {
  GOOGLE: "GOOGLE",
};
export const ERROR_STATUS_CODE = {
  EMAIL_VERIFIED_ERROR: "alg_wc_ev_email_verified_error",
  INCORRECT_PASSWORD: "incorrect_password",
  INVALID_EMAIL: "invalid_email",
};

export const PAGES = {
  CONTACT_US: "/contact",
  STORIES: "/stories",
  CONTACT_SUBMITTED: "/contact-submitted",
  UNSUBSCRIBE: "/unsubscribe",
  POLICY: "/policy",
  LOGIN: "/login",
  REGISTER: "/register",
};

export const QUERY_MOBILE = "62em";

export const SECTION_IDS = {
  JOIN_NOW: "joinnow",
  JOIN_BETA: "joinbeta",
  DOWNLOAD: "download",
};

export const MAILTO_ADDRESS = "mailto:operations@rayyone.com";
export const MAILTO_HELLO_ADDRESS = "mailto:hello@rayyone.com";

export const appStoreLink = "https://apps.apple.com/us/app/mr-thing-reminders-tasks/id1670084691";

export const googlePlayLink = "https://play.google.com/store/apps/details?id=com.mrthing";
