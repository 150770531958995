import { Colors, extendTheme, ThemeComponents, ThemeConfig, withDefaultColorScheme, withDefaultSize } from "@chakra-ui/react";

const fonts = {
  heading: "Inter",
  body: "Inter",
};
const colors: Colors = {
  // Base color: #FB8C00. Generated by https://smart-swatch.netlify.app
  main: {
    50: "#E6F6FF",
    100: "#CCEDFF",
    200: "#B3E4FF",
    300: "#99DBFF",
    400: "#80D3FF",
    500: "#66CAFF",
    600: "#4DC1FF",
    700: "#33B8FF",
    800: "#1A9EE6",
    900: "#1f0c00",
    primary: "#00A6FF",
    border: "#D3D3D3",
    text: "#282132",
    background: "#DDF3FE",
  },
  grey: {
    200: "#AEAEAE",
  },
  black: {
    100: "#575757",
    400: "#305853",
  },
  link: {
    active: "#FB8C00",
  },
  secondary: {
    50: "#daffff",
    100: "#afffff",
    200: "#80ffff",
    300: "#52ffff",
    400: "#31fefe",
    500: "#24e4e5",
    600: "#12b2b3",
    700: "#007f80",
    800: "#004c4e",
    900: "#001b1c",
    text: "#666666",
    col: "#D9D9D933",
    font: "#793505",
  },
};
const fontSizes = {
  xs: "0.625rem", // 10px
  "2xs": "0.75rem", // 12px
  "3xs": "0.875rem", // 14px
  "4xs": "1rem", // 16px
  "5xs": "1.125rem", // 18px
  sm: "1.25rem", // 20px
  "2sm": "1.5625rem", // 25px
  "3sm": "1.875rem", // 30px
  "4sm": "2.1875rem", // 35px
  md: "2.5rem", // 40px
  lg: "3.125rem", // 50px
  "2lg": "4.375rem", // 70px
  xl: "5rem", // 80px
  "2xl": "1.6rem",
  "3xl": "1.9rem",
  "4xl": "2.2rem",
  "5xl": "2.8rem",
  "6xl": "3.4rem",
  "7xl": "4rem",
  "8xl": "5.2rem",
  "9xl": "6.8rem",
  size16: "1rem",
};

const lineHeights = {
  xs: "0.875rem", // 14px
  sm: "1.25rem", // 20px
  "2sm": "1.5625rem", // 25px
  "3sm": "1.875rem", // 30px
  "4sm": "2.1875rem", // 35px
  md: "2.5rem", // 40px
  lg: "3.125rem", // 50px
  "2lg": "3.25rem", // 52px
  "3lg": "3.563rem", // 57px
  "4lg": "4.5rem", // 72px
  xl: "5rem", // 80px
  "2xl": "1.6rem",
  "3xl": "1.9rem",
  "4xl": "2.2rem",
  "5xl": "2.8rem",
  "6xl": "3.4rem",
  "7xl": "5.625rem", // 90px
  "8xl": "5.9375rem", // 95px
  "9xl": "6.8rem",
};

const sizes = {
  full: "100%",
  "3xs": "10.2rem",
  "2xs": "12.8rem",
  xs: "15rem",
  sm: "17rem",
  md: "20.4rem",
  lg: "23.6rem",
  xl: "25.8rem",
  "1": "0.2rem",
  "2": "0.4rem",
  "3": "0.6rem",
  "4": "0.8rem",
  "5": "1rem",
  "6": "1.25rem",
  "7": "1.5rem",
  "8": "1.97rem",
  "9": "2.1rem",
  "10": "2.25rem",
  "12": "2.5rem",
  "14": "3rem",
  "16": "3.5rem",
  "20": "4rem",
  "24": "5rem",
  "28": "6rem",
  "32": "7rem",
  "36": "8rem",
  "40": "9rem",
  "44": "10rem",
  "48": "11rem",
  "52": "12rem",
  "56": "13rem",
  "60": "14rem",
  "64": "15rem",
  "72": "16rem",
  "80": "18rem",
  "96": "20rem",
  px: "24rem",
  "0.5": "1px",
  "1.5": "0.125rem",
  "2.5": "0.375rem",
  "3.5": "0.625rem",
  "2xl": "25.8rem",
  "3xl": "30.6rem",
  "4xl": "35.4rem",
  "5xl": "40.8rem",
  "6xl": "45.2rem",
  "7xl": "50.6rem",
  "8xl": "80rem",
};
const spacing = {
  space: {
    px: "1px",
    0.5: "0.125rem",
    1: "0.25rem",
    1.5: "0.3rem",
    2: "0.37rem",
    2.5: "0.4rem",
    3: "0.5rem",
    3.5: "0.7rem",
    4: "0.8rem",
    5: "0.9rem",
    6: "1rem",
    7: "1.5rem",
    8: "1.75rem",
    9: "2rem",
    10: "2.25rem",
    12: "2.5rem",
    14: "3rem",
    16: "3.5rem",
    20: "4rem",
    24: "5rem",
    28: "6rem",
    32: "7rem",
    36: "8rem",
    40: "9rem",
    44: "10rem",
    48: "11rem",
    52: "12rem",
    56: "13rem",
    60: "14rem",
    64: "15rem",
    72: "16rem",
    80: "17rem",
    96: "19rem",
  },
};

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const shadows = {
  outline: "0 0 0 3px #49696b",
};

const components: ThemeComponents = {
  Button: {
    baseStyle: {
      borderRadius: 50,
    },
    variants: {
      solid: {
        px: "22px",
      },
    },
    sizes: {
      sm: {
        minW: "40px",
      },
    },
  },
  Link: {
    baseStyle: {
      textDecoration: "none",
    },
  },
  FormLabel: {
    baseStyle: {
      fontSize: "sm",
    },
  },
  Text: {
    baseStyle: {
      fontSize: "1.25rem",
      color: "#282132",
    },
  },
  Heading: {
    baseStyle: {
      color: "#282132",
      fontFamily: "Inter",
      fontWeight: "400px",
    },
    variants: {
      base: {
        fontSize: "lg",
      },
      md: {
        fontSize: "lg",
      },
      lg: {
        fontSize: "xl",
      },
    },
  },
  Input: {
    defaultProps: {
      variant: "flushed",
    },
    baseStyle: {
      field: {
        color: "teal.500", // change the input text color
      },
    },
    variants: {
      outline: {
        field: {
          border: "1px solid",
          borderColor: "teal.500",
          _hover: {
            borderColor: "teal.600",
          },
          _focus: {
            borderColor: "teal.700",
          },
        },
      },
      flushed: {
        field: {
          height: "20px",
          _hover: {
            borderColor: "teal.600",
          },
          _focus: {
            borderColor: "teal.700",
          },
        },
      },
    },
  },
  Textarea: {
    baseStyle: {
      color: "teal.500", // change the input text color
    },
    variants: {
      outline: {
        border: "1px solid",
        borderColor: "teal.500",
        _hover: {
          borderColor: "teal.600",
        },
      },
    },
  },
};

export const uiTheme = extendTheme(
  {
    colors,
    config,
    components,
    shadows,
    fonts,
    fontSizes,
    sizes,
    lineHeights,
    ...spacing,
  },
  withDefaultColorScheme({ colorScheme: "main" }),
  withDefaultSize({ size: "sm" }),
);
