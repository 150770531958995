/* eslint-disable */
export function ignoreLogs() {
  const backup = console.error;
  console.error = function filterWarnings(msg, ...optionalParams) {
    const suppressedWarnings = [
      { text: "is deprecated in StrictMode", params: ["findDOMNode"] },
    ];
    if (
      !suppressedWarnings.some(
        (entry) =>
          msg.includes(entry.text) &&
          entry.params &&
          optionalParams.indexOf(entry.params)
      )
    ) {
      // @ts-ignore
      backup.apply(console, arguments);
    }
  };
}
