import React from "react";
import { AppContainer, Col, Row, UiBox, UiButton, UiHeading, UiImage, UiText, useMediaQuery } from "~/components/elements";
import { sharedStyle } from "~/theme/shared-style";
import { scroller } from "react-scroll";
import { QUERY_MOBILE } from "~/constants";
import { AiOutlineArrowRight } from "react-icons/ai";
import { MotionSlideFade } from "~/components/shared/MotionSlideFade";
import { imageCreateReminders, imageRepetitiveWork, imageSmartSnoozes, phoneManage } from "~/assets/img";
import { LazyLoadImage } from "react-lazy-load-image-component";

const TopFeature = () => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {
    ssr: false,
  });

  const handleClickGetStarted = () => {
    scroller.scrollTo("joinnow", {
      smooth: true,
      duration: 1000,
    });
  };

  return (
    <UiBox mb="0px" pos="relative">
      <AppContainer h="100%" as={Row} maxW={sharedStyle.menuMaxW}>
        <Col flex={{ lg: 1 }} mx={{ base: "5%", lg: "0%" }} marginTop={{ base: "15%", lg: "8%" }} textAlign={{ base: "center", lg: "left" }}>
          <UiBox as={isDesktop ? Row : Col} mb={{ base: 20, lg: 200 }} justifyContent="space-between" alignItems="center">
            <Col mb={{ base: 10, lg: 0 }}>
              <MotionSlideFade>
                <UiHeading mb={{ base: 10, lg: 0 }} fontSize="lg" fontWeight="bold">
                  Create{" "}
                  <UiText as="span" fontSize={{ base: "lg" }} color="main.primary">
                    reminders {isDesktop && <br />}
                  </UiText>{" "}
                  instantly. {isDesktop && <br />} Save time for others {isDesktop && <br />} that more matter.
                </UiHeading>
                <UiText fontSize={{ base: "3xs", lg: "sm" }} color="secondary.text">
                  Life is not easy, don’t let your day be more messy.
                </UiText>
              </MotionSlideFade>
            </Col>
            <Col width={{ base: "250px", lg: "520px" }} height={{ base: "300px", lg: "539px" }}>
              <UiImage src={imageCreateReminders} style={{ borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }} />
            </Col>
          </UiBox>
          <UiBox
            as={isDesktop ? Row : Col}
            mb={{ base: 20, lg: 200 }}
            flexDirection={{ lg: "row-reverse" }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Col mr={{ lg: "70px" }} mb={{ base: 10, lg: 0 }}>
              <MotionSlideFade>
                <UiHeading mb={{ base: 10, lg: 0 }} fontSize="lg" fontWeight="bold">
                  Receive{" "}
                  <UiText as="span" fontSize={{ base: "lg" }} color="main.primary">
                    smart {isDesktop && <br />} snoozes
                  </UiText>{" "}
                  until you {isDesktop && <br />} make a move.
                </UiHeading>
                <UiText fontSize={{ base: "3xs", lg: "sm" }} color="secondary.text">
                  Help you and your team stay on track and {isDesktop && <br />} receive reminders even when there is no internet{" "}
                  {isDesktop && <br />} connection.
                </UiText>
              </MotionSlideFade>
            </Col>
            <Col width={{ base: "289px", lg: "520px" }} height={{ base: "300px", lg: "542px" }}>
              <UiImage src={imageSmartSnoozes} />
            </Col>
          </UiBox>
          <UiBox as={isDesktop ? Row : Col} justifyContent="space-between" alignItems="center">
            <Col>
              <MotionSlideFade>
                <UiHeading mb={{ base: 10, lg: 0 }} fontSize="lg" fontWeight="bold">
                  Because you don’t {isDesktop && <br />} have time for {isDesktop && <br />} repetitive work.
                </UiHeading>
              </MotionSlideFade>
            </Col>
            <Col width={{ base: "289px", lg: "520px" }} height={{ base: "300px", lg: "542px" }}>
              <UiImage src={imageRepetitiveWork} />
            </Col>
          </UiBox>
        </Col>
      </AppContainer>
    </UiBox>
  );
};
export default TopFeature;
