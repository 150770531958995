import times from "lodash/times";
import { ENDPOINTS } from "~/constants";

const bookItem = () => ({
  id: 0,
  name: "faker.lorem.sentence()",
});

const books = {
  [ENDPOINTS.USERS]: {
    get: times(5, Number).map((i) => ({ ...bookItem(), id: i + 1 })),
  },
};

export default books;
