import React from "react";
import { useHistory } from "react-router-dom";
import {
  AppContainer,
  Col,
  UiButton,
  UiIcon,
  UiText,
  UiTitle,
} from "~/components/elements";
import { useScrollToTop } from "~/hooks";
import { PageLayout } from "~/components/layouts/PageLayout";
import { AiOutlineShake } from "react-icons/ai";

function ContactSubmitted() {
  const history = useHistory();

  useScrollToTop();

  return (
    <PageLayout
      metaTagsConfig={{
        metas: [{ name: "robots", content: "noindex,nofollow" }],
      }}
    >
      <AppContainer>
        <Col h="100vh" justifyContent={{ base: "center", lg: "end" }}>
          <Col
            h={{ base: "70%", lg: "90%" }}
            alignItems="center"
            justifyContent="space-evenly"
            textAlign="center"
          >
            <UiTitle fontSize="6xl">Thank you for contacting us!</UiTitle>
            <UiText fontSize="xl">
              We have well received your message.
              <br />
              Your message will be answered by us promptly the same or next
              business day!
            </UiText>
            <UiIcon as={AiOutlineShake} boxSize={40} />
            <UiButton color="white" onClick={() => history.push("/")} size="lg">
              Back to Home Page
            </UiButton>
          </Col>
        </Col>
      </AppContainer>
    </PageLayout>
  );
}

export default ContactSubmitted;
