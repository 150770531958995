import { appConfig } from "~/config";

const API_HOST = appConfig.api.host;
const API_URL = appConfig.api.url;

export const ENDPOINTS = {
  LOGIN: `login`,
  SOCIAL_LOGIN: `auth/social/login`,
  LOGOUT: `auth/logout`,
  CURRENT_USER: `me`,
  USERS: `users`,
  FILE_UPLOAD: "FILE_UPLOAD",
  FILE_TEMPORARY_URL: "uploads/tmp",
  MAIL_UNSUBSCRIBES: "/unsubscribes",
};
