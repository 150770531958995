import React from "react";
import { phoneBanner } from "~/assets/img";
import { AppContainer, Col, Row, UiBox, UiButton, UiHeading, UiText, useMediaQuery } from "~/components/elements";
import { sharedStyle } from "~/theme/shared-style";
import { QUERY_MOBILE, SECTION_IDS } from "~/constants";
import { MotionSlideFade } from "~/components/shared/MotionSlideFade";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { scroller } from "react-scroll";

function Banner() {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {
    ssr: false,
  });

  const handleClickGetStarted = () => {
    scroller.scrollTo(SECTION_IDS.DOWNLOAD, {
      smooth: true,
      duration: 1000,
      offset: -300,
    });
  };

  return (
    <UiBox mb="0px" pb="50px" bg="main.background" pos="relative">
      <AppContainer h="100%" as={Row} justifyContent="flex-start" maxW={sharedStyle.menuMaxW}>
        <Col flexDirection={{ base: "column", lg: "row" }} marginTop={{ base: "10px", lg: "130px" }} mb={{ lg: 20 }}>
          <Col marginTop="30px" pr={{ lg: 60 }} textAlign={{ base: "center", lg: "left" }}>
            <MotionSlideFade>
              <UiHeading fontSize={{ base: "lg", md: "lg", lg: "2lg" }} fontWeight="bold" lineHeight={{ base: "lg", lg: "4lg" }}>
                Re-define the way {isDesktop && <br />} your{" "}
                <UiText as="span" color="main.primary" fontSize={{ base: "lg", md: "lg", lg: "2lg" }}>
                  team works
                </UiText>
                .
              </UiHeading>
            </MotionSlideFade>
            <MotionSlideFade>
              <UiText
                py={{ base: 10 }}
                color="secondary.text"
                whiteSpace="-moz-pre-wrap"
                fontSize={{ base: "3xs", lg: 22 }}
                lineHeight={{ base: "2sm", lg: "4sm" }}
              >
                a revolutionary reminder & to do app that helps you and your {isDesktop && <br />}
                team stay away from procrastination.
              </UiText>
            </MotionSlideFade>

            <MotionSlideFade>
              <Row gap={6} paddingBottom={{ base: 10 }} justifyContent={{ base: "center", lg: "flex-start" }}>
                <UiButton
                  onClick={handleClickGetStarted}
                  lineHeight="3sm"
                  fontSize={{ base: "sm" }}
                  fontFamily="Inter"
                  fontWeight="600"
                  borderRadius={12}
                  colorScheme="twitter"
                  bg="black"
                  height={{ base: "50px", lg: "64px" }}
                >
                  Get started
                </UiButton>
              </Row>
            </MotionSlideFade>
          </Col>
          <Col width={{ base: "200px", lg: "301px" }} height={{ base: "450px", lg: "615px" }} alignSelf="center">
            <LazyLoadImage height="100%" width="100%" src={phoneBanner} effect="blur" />
          </Col>
        </Col>
      </AppContainer>
    </UiBox>
  );
}

export default Banner;
